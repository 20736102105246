import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import styles from './styles';

/**
 * Screen container wraps any given component and adds padding to
 * the top of the screen as well as the default background color for
 * consistent styling across pages
 */
const ScreenContainer = (props) => {
    const { classes, paddingBottom, children, disableTopPadding } = props;

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.content, {
                    [classes.footerPadding]: paddingBottom,
                    [classes.disableTopPadding]: disableTopPadding,
                })}
            >
                {children}
            </div>
        </div>
    );
};

ScreenContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    paddingBottom: PropTypes.bool,
    disableTopPadding: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

ScreenContainer.defaultProps = {
    paddingBottom: false,
    disableTopPadding: false,
};

export default withStyles(styles)(ScreenContainer);
