import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Check, SquareSmall } from 'mdi-material-ui';
import styles from './styles';
import clsx from 'clsx';
import {
    isAtLeastEightChars,
    hasLowerCaseLetter,
    hasUpperCaseLetter,
    hasSpecialCharacter,
    hasDigit,
    notTooGuessable,
} from '../../lib/auth';

const PasswordFeedback = (props) => {
    const { classes, password } = props;

    const getStyledText = (text, active) => {
        return (
            <Typography
                variant={'caption'}
                component={'span'}
                className={clsx(classes.feedbackRoot, {
                    [classes.notSatisfiedFeedback]: !active,
                    [classes.positiveFeedback]: active,
                })}
            >
                {active && (
                    <div className={classes.feedbackIconContainer}>
                        <Check className={classes.checkmark} />
                    </div>
                )}
                {!active && (
                    <div className={classes.feedbackIconContainer}>
                        <SquareSmall className={classes.checkmark} />
                    </div>
                )}
                {text}
            </Typography>
        );
    };

    return (
        <Typography variant={'caption'} color={'textPrimary'} component={'span'}>
            {'Password Requirements: '}
            {getStyledText('At least 8 characters ', isAtLeastEightChars(password))}
            {getStyledText('1 lowercase letter ', hasLowerCaseLetter(password))}
            {getStyledText(' 1 uppercase letter ', hasUpperCaseLetter(password))}
            {getStyledText(
                '1 special character (not + or =) ',
                hasSpecialCharacter(password),
            )}
            {getStyledText('1 number ', hasDigit(password))}
            {getStyledText('Not guessable', notTooGuessable(password))}
        </Typography>
    );
};

PasswordFeedback.propTypes = {
    password: PropTypes.string.isRequired,
};

PasswordFeedback.defaultProps = {
    variant: 'dark',
};

const PasswordFeedbackWithStyles = withStyles(styles)(PasswordFeedback);
export default React.memo(PasswordFeedbackWithStyles, (prev, next) => {
    if (prev.password !== next.password) {
        return false;
    }

    return true;
});
