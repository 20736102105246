import { Facebook, Twitter, Soundcloud, Instagram, Youtube } from 'mdi-material-ui';

const DOMAIN_BASE = process.env.cablecarBaseRedirect;

const SHARED_LEGACY_ROUTES = {
    FEATURES: {
        route: `${DOMAIN_BASE}/category/features/`,
        displayText: 'features',
    },
    SHOP: {
        route: 'https://shop.elevatormag.com/',
        displayText: 'shop',
    },
    SUBMIT: {
        route: process.env.submissionsRedirect,
        displayText: 'submit',
    },
    ADVERTISE: {
        route: `${DOMAIN_BASE}/advertising/`,
        displayText: 'advertise',
    },
};

const NAVIGATION_ROUTES = {
    ROOT: {
        route: '/',
        displayText: 'Submit',
    },
    SIGN_IN: {
        route: '/auth/signin',
        uri: process.env.authRedirect,
        displayText: 'Sign In',
    },
    ADMIN_DASHBOARD: {
        route: '',
        uri: process.env.spotterRedirect,
        displayText: 'Go To Admin Dashboard',
    },
    RESET_PASSWORD: {
        route: '/auth/reset-password',
        uri: 'https://auth.elevatormag.com/reset-password',
        displayText: 'Reset Password',
    },
    SIGN_UP: {
        route: '/auth/signup',
        uri: 'https://auth.elevatormag.com/signup',
        displayText: 'Sign Up',
    },
    USERS: {
        route: '/users',
        display: 'Users',
    },
    ACCOUNT: {
        route: '/account',
        display: 'Account',
        ACCOUNT_SETTINGS: {
            route: '/account/settings',
            display: 'Account Settings',
        },
        SUBMISSIONS: {
            route: '/account/submissions',
            display: 'Your Submissions',
        },
    },
    PROFILE: {
        route: '/profile',
        display: 'Profile',
        EDIT_PROFILE: {
            route: '/profile/edit',
            display: 'Edit Profile',
        },
    },
    VERIFY_EMAIL: {
        route: '/verify',
        display: 'Verify Email',
    },
    LEGACY: {
        HEADER: {
            MUSIC: {
                route: `${DOMAIN_BASE}/category/music/`,
                displayText: 'music',
            },
            VIDEO: {
                route: `${DOMAIN_BASE}/category/videos/`,
                displayText: 'videos',
            },
            FEATURES: SHARED_LEGACY_ROUTES.FEATURES,
            INTERVIEWS: {
                route: `${DOMAIN_BASE}/category/interviews/`,
                displayText: 'interviews',
            },
            SHOP: SHARED_LEGACY_ROUTES.SHOP,
            SUBMIT: SHARED_LEGACY_ROUTES.SUBMIT,
            ADVERTISE: SHARED_LEGACY_ROUTES.ADVERTISE,
            FAQ: {
                route: `${DOMAIN_BASE}/faq/`,
                displayText: 'faq',
            },
        },
        FOOTER: {
            ...SHARED_LEGACY_ROUTES,
            STAFF: {
                route: `${DOMAIN_BASE}/staff/`,
                displayText: 'staff',
            },
            CONTACT: {
                route: `${DOMAIN_BASE}/contact/`,
                displayText: 'contact',
            },
        },
    },
};

const SOCIAL_MEDIA = {
    FACEBOOK: {
        route: 'https://www.facebook.com/ElevatorMag/',
        icon: Facebook,
    },
    TWITTER: {
        route: 'https://twitter.com/elevator_',
        icon: Twitter,
    },
    SOUNDCLOUD: {
        route: 'https://soundcloud.com/lvtrmag',
        icon: Soundcloud,
    },
    INSTAGRAM: {
        route: 'https://www.instagram.com/elevator_/',
        icon: Instagram,
    },
    YOUTUBE: {
        route: 'https://www.youtube.com/user/LVTRmag',
        icon: Youtube,
    },
};

export { NAVIGATION_ROUTES, DOMAIN_BASE, SOCIAL_MEDIA };
