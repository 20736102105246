import React from 'react';
import { Typography, withStyles, Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const SectionSeparator = (props) => {
    const { classes, text, ...rest } = props;

    return (
        <Box width={'100%'} {...rest}>
            <Typography variant={'h6'} className={classes.textContent}>
                {text}
            </Typography>
            <Divider variant={'fullWidth'} className={classes.divider} />
        </Box>
    );
};

SectionSeparator.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
};

export default withStyles(styles)(SectionSeparator);
