import React from 'react';
import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Typography,
    withStyles,
    Divider,
} from '@material-ui/core';
import {
    AccountBoxOutlined as MyAccountIcon,
    ExitToAppOutlined as SignOutIcon,
} from '@material-ui/icons';
import {
    InboxArrowUp,
    Settings,
    FileDocumentBoxPlusOutline,
    MonitorDashboard,
} from 'mdi-material-ui';
import Router from 'next/router';
import { UserBadge } from '../';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

/**
 * Account Popover component: a popup card used to display account information
 * about the user as well as clickable options for navigation.
 */
const AccountPopover = (props) => {
    const {
        anchorEl,
        avatarURL,
        userName,
        isPreferred,
        isElevatorStaff,
        isVerified,
        email,
        classes,
        setAnchorEl,
        handleSignoutClick,
    } = props;
    const open = Boolean(anchorEl);
    const id = open ? 'account-popover' : undefined;

    // Configurable Menu Options as JS objects
    const menuOptions = [
        {
            listItemIcon: <MyAccountIcon />,
            listItemText: 'My Profile',
            onListItemPress: () => {
                Router.push(NAVIGATION_ROUTES.PROFILE.route);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            listItemIcon: <InboxArrowUp />,
            listItemText: 'My Submissions',
            onListItemPress: () => {
                Router.push(NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS.route);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            listItemIcon: <FileDocumentBoxPlusOutline />,
            listItemText: 'New Submission',
            onListItemPress: () => {
                Router.push(NAVIGATION_ROUTES.ROOT.route);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            seperator: true,
        },
        {
            listItemIcon: <Settings />,
            listItemText: 'Account Settings',
            onListItemPress: () => {
                Router.push(NAVIGATION_ROUTES.ACCOUNT.ACCOUNT_SETTINGS.route);
            },
            beta: false,
            highEmphasis: false,
        },
        ...(isElevatorStaff
            ? [
                  {
                      listItemIcon: <MonitorDashboard />,
                      listItemText: 'Go To Admin Dashboard',
                      href: NAVIGATION_ROUTES.ADMIN_DASHBOARD.uri,
                      beta: false,
                      highEmphasis: true,
                  },
              ]
            : []),
        {
            listItemIcon: <SignOutIcon />,
            listItemText: 'Sign Out',
            onListItemPress: handleSignoutClick,
            beta: false,
            highEmphasis: false,
        },
    ];

    /**
     * Wrapping function which assures that the AccountPopover component
     * closes upon the callback of the parameter function.
     */
    const handleListItemPress = (itemOnPress) => {
        itemOnPress();
        handleClose();
    };

    /**
     * Handler function for closing the popover menu by setting its achor point ref to null
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Dynamically Renders Menu Items based on JS object configuration
     */
    const renderPopoverMenuItems = () => {
        return menuOptions.map((menuOptionConfig, index) => {
            if (menuOptionConfig.seperator) {
                return <Divider key={index} className={classes.listSep} />;
            }

            const {
                listItemIcon,
                listItemText,
                onListItemPress,
                beta,
                href,
                highEmphasis,
            } = menuOptionConfig;

            return (
                <MenuItem
                    key={index}
                    component={Boolean(href) ? 'a' : 'li'}
                    href={href}
                    onClick={
                        onListItemPress
                            ? () => {
                                  handleListItemPress(onListItemPress);
                              }
                            : null
                    }
                    disabled={beta}
                >
                    <ListItemIcon
                        className={clsx({
                            [classes.defaultColor]: !highEmphasis,
                            [classes.highEmphasis]: highEmphasis,
                        })}
                    >
                        {listItemIcon}
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: clsx(
                                classes.listItemText,
                                highEmphasis && classes.highEmphasis,
                            ),
                        }}
                        primary={beta ? `${listItemText} (Coming Soon)` : listItemText}
                    />
                </MenuItem>
            );
        });
    };

    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                paper: classes.disablePaperMargin,
            }}
        >
            <div id="menu" className={classes.accountMenu}>
                <div className={classes.cardHeader}>
                    <div className={classes.accountInfoContainer}>
                        <Typography
                            component={'span'}
                            variant={'h6'}
                            className={classes.userName}
                        >
                            {userName}
                            {isElevatorStaff && <UserBadge variant={'staff'} />}
                            {isPreferred && <UserBadge variant={'preferred'} />}
                            {isVerified && <UserBadge variant={'verified'} />}
                        </Typography>
                        <Typography
                            component={'p'}
                            variant={'body2'}
                            color={'textSecondary'}
                        >
                            {email}
                        </Typography>
                    </div>
                </div>
                <MenuList>{renderPopoverMenuItems()}</MenuList>
            </div>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    avatarURL: PropTypes.string,
    userName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    handleSignoutClick: PropTypes.func.isRequired,
    isPreferred: PropTypes.bool,
    isElevatorStaff: PropTypes.bool,
    isVerified: PropTypes.bool,
};

export default withStyles(styles)(AccountPopover);
