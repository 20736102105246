const styles = (theme) => ({
    root: {
        width: '100%',
    },
    disablePaperMargins: {
        padding: 0,
        backgroundColor: theme.palette.background.default,
    },
    listItem: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
    listItemMargin: {
        margin: theme.spacing(1, 0),
    },

    cardHeaderContainer: {
        padding: theme.spacing(2),
    },
    cardBodyContainer: {
        padding: theme.spacing(2),
    },
    attachedImage: {
        width: '120px',
        height: '120px',
        objectFit: 'cover',
    },

    actionButton: {
        minWidth: '200px',
    },
    shareIcon: {
        fontSize: theme.typography.pxToRem(12),
    },
    infoIcon: {
        color: theme.palette.submissionStatuses.flagged,
    },
    openExternalIcon: {
        fontSize: theme.typography.pxToRem(12),
    },
});

export default styles;
