const styles = (theme) => ({
    disablePaperMargins: {
        margin: 0,
        padding: 0,
        width: '100%',
        minHeight: '400px',
    },
    thumbnail: {
        width: '100%',
        height: '180px',
        objectFit: 'cover',
        objectPosition: '50% 20%',
    },
    authorLink: {
        textDecoration: 'none',
    },
    metadataText: {
        fontSize: theme.typography.pxToRem(12),
    },
    postTitle: {
        fontSize: theme.typography.pxToRem(20),
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            color: 'red',
        },
    },
});

export default styles;
