import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const disclaimerText = `We use 🍪 cookies to secure your sessions and data while using our application(s). If you have questions about our cookie usage, please contact support at support@elevatormag.com.`;
const disclaimerDate = '01-22-2020';
const disclaimerKey = `cookieDisclaimer_${disclaimerDate}`;

const CookieDisclaimer = (props) => {
    const { classes } = props;

    React.useEffect(() => {
        shouldRenderDisclaimer();
    }, []);

    const [renderDisclaimer, setRenderDisclaimer] = React.useState(false);

    const shouldRenderDisclaimer = () => {
        if (!window) return false;

        let hasReadDisclaimer = window.localStorage.getItem(disclaimerKey);

        setRenderDisclaimer(hasReadDisclaimer ? false : true);
    };

    const setDisclaimerAsRead = () => {
        if (!window) return;

        window.localStorage.setItem(disclaimerKey, true);
        setRenderDisclaimer(false);
    };

    return renderDisclaimer ? (
        <Box
            position={'fixed'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.cookieDisclaimer}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                className={classes.cookieDisclaimerContent}
            >
                <Typography className={classes.cookieDisclaimerText}>
                    {disclaimerText}
                </Typography>
                <Button
                    onClick={setDisclaimerAsRead}
                    color={'secondary'}
                    variant={'outlined'}
                >
                    Okay
                </Button>
            </Box>
        </Box>
    ) : null;
};

CookieDisclaimer.propTypes = {};

export default withStyles(styles)(CookieDisclaimer);
