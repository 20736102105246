import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

const LegacyNavLink = (props) => {
    const { classes, lowContrastText, fontSizeType, color, ...rest } = props;

    return (
        <a
            {...rest}
            className={clsx(
                classes.legacyLink,
                lowContrastText && classes.lowContrastText,
                fontSizeType === 'large' && classes.largeFont,
                color === 'grey' && classes.greyText,
                color === 'red' && classes.redText,
            )}
        >
            {props.children}
        </a>
    );
};

LegacyNavLink.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    lowContrastText: PropTypes.bool,
    fontSizeType: PropTypes.oneOf(['regular', 'large']),
    color: PropTypes.oneOf(['white', 'grey', 'red']),
};

LegacyNavLink.defaultProps = {
    lowContrastText: false,
    fontSizeType: 'regular',
    color: 'white',
};

export default withStyles(styles)(LegacyNavLink);
