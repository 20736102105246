import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import { Info } from '@material-ui/icons';
import clsx from 'clsx';

const Callout = (props) => {
    const { classes, children } = props;

    return (
        <div className={classes.root}>
            <div className={classes.calloutBox}>
                <Info className={classes.calloutTextIcon} />
                <Typography
                    variant={'body2'}
                    className={classes.calloutTextSpacing}
                    component={'span'}
                >
                    {children}
                </Typography>
            </div>
        </div>
    );
};

Callout.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withStyles(styles)(Callout);
