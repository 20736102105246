const SOCIAL_MEDIA_TYPES = {
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    SOUNDCLOUD: 'soundcloud',
    TWITTER: 'twitter',
    FACEBOOK: 'facebook',
};

Object.freeze(SOCIAL_MEDIA_TYPES);

export default SOCIAL_MEDIA_TYPES;
