import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { Eye, EyeOff } from 'mdi-material-ui';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

const TogglePasswordsButton = (props) => {
    const { classes, showPasswords, toggleShowPasswords, variant, ...rest } = props;

    return (
        <Button
            onClick={toggleShowPasswords}
            variant={'text'}
            color={'secondary'}
            className={clsx(
                classes.togglePasswordsButton,
                variant === 'light' && classes.lightVariantText,
            )}
            {...rest}
        >
            {showPasswords ? (
                <React.Fragment>
                    <EyeOff
                        className={clsx(
                            classes.toggleEyeIcon,
                            variant === 'light' && classes.lightVariantText,
                        )}
                    />
                    Hide Passwords
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Eye
                        className={clsx(
                            classes.toggleEyeIcon,
                            variant === 'light' && classes.lightVariantText,
                        )}
                    />
                    Show Passwords
                </React.Fragment>
            )}
        </Button>
    );
};

TogglePasswordsButton.propTypes = {
    classes: PropTypes.object.isRequired,
    showPasswords: PropTypes.bool.isRequired,
    toggleShowPasswords: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['light', 'dark']),
};

TogglePasswordsButton.defaultProps = {
    variant: 'dark',
};

export default withStyles(styles)(TogglePasswordsButton);
