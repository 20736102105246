import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

const ActionAreaContainer = (props) => {
    const { classes, children, direction } = props;

    return (
        <div
            className={clsx(classes.actionAreaContainer, {
                [classes.alignRow]: direction === 'row',
                [classes.alignColumn]: direction === 'column',
                [classes.alignColumnReverse]: direction === 'columnReverse',
                [classes.alignRowReverse]: direction === 'rowReverse',
            })}
        >
            {children}
        </div>
    );
};

ActionAreaContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
    direction: PropTypes.oneOf(['row', 'column', 'rowReverse', 'columnReverse']),
};

ActionAreaContainer.defaultProps = {
    direction: 'row',
};

export default withStyles(styles)(ActionAreaContainer);
