import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slider,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
    CircularProgress,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import Cropper from 'react-easy-crop';
import { cropImage } from './CropImage';
import { reportError } from '../../lib/errors';
import PropTypes from 'prop-types';
import styles from './styles';

const ImageCropperDialogue = (props) => {
    const {
        classes,

        cropCandidate,
        onImageCropped,
        aspect,

        // Modal Props
        onClose,
        enqueueSnackbar,
        onImageCropCancel,

        // Rest props are passed to <Dialogue /> component
        ...rest
    } = props;

    // Create object URL from file
    const cropCandidateUrl = cropCandidate && URL.createObjectURL(cropCandidate);

    // Retrieve information about the current view dimensions
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Cropper state management
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
    const minZoom = 0.1;

    // Loading state
    const [isCropping, setIsCropping] = React.useState(false);

    /**
     * Called every time a zoom percentage is changed
     */
    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };

    /**
     * Called every single time crop area is moved
     */
    const onCropChange = (crop) => {
        setCrop(crop);
    };

    /**
     * Called when the user lets go of the view box which signifies a complete interaction
     */
    const onCropComplete = (_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    /**
     * Callback for when the hits 'Finished' This will crop the image call parent function with cropped image
     */
    const onFinishClick = async () => {
        setIsCropping(true);
        try {
            const croppedImageResult = await cropImage(
                cropCandidateUrl,
                croppedAreaPixels,
                cropCandidate,
            );

            // Pass result to parent cb
            onImageCropped(croppedImageResult);
            onClose();
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'user tried to crop image',
                },
            });
            showErrorSnackback('An error ocurred while cropping your image');
        }
        setIsCropping(false);
    };

    const showErrorSnackback = (msg) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'error',
            preventDuplicate: true,
        });
    };

    const onLoadImageError = (err) => {
        reportError(err, {
            metaData: {
                operation: 'Failed to load image into ImageCropperDialogue',
            },
        });
        showErrorSnackback('Error Loading Image');
        onClose();
    };

    const renderDialogueHeader = () => {
        return (
            <AppBar elevation={0} position={'fixed'} className={classes.appbar}>
                <div className={classes.appBarToolbar}>
                    <IconButton onClick={onClose} color="inherit" aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6">Crop Image</Typography>
                </div>
            </AppBar>
        );
    };

    const renderImageCropArea = () => {
        return (
            <Box paddingTop={2}>
                <Box position={'relative'} minHeight={'350px'}>
                    <Cropper
                        image={cropCandidateUrl}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        restrictPosition={false}
                        classes={{
                            containerClassName: classes.cropperContainer,
                        }}
                        aspect={aspect}
                        minZoom={minZoom}
                        onImgError={onLoadImageError}
                    />
                </Box>
            </Box>
        );
    };

    const renderZoomSlider = () => {
        return (
            <Box
                paddingY={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Slider
                    color={'secondary'}
                    defaultValue={0}
                    aria-labelledby="discrete-zoom-slider"
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={minZoom}
                    max={3}
                    onChange={(_, value) => onZoomChange(value)}
                    value={zoom}
                />
                <Box paddingLeft={1}>
                    <Typography
                        variant={'caption'}
                        id="discrete-zoom-slider"
                        gutterBottom
                    >
                        Zoom
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            PaperProps={{
                classes: {
                    root: classes.paperStyle,
                },
            }}
            fullScreen={mobile}
            disableBackdropClick
            disableEscapeKeyDown
            {...rest}
        >
            {!mobile && <DialogTitle id={'scroll-dialog-title'}>Crop Image</DialogTitle>}
            {mobile && renderDialogueHeader()}
            <DialogContent dividers>
                {isCropping ? (
                    <Box
                        minHeight={mobile ? '100%' : '350px'}
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <CircularProgress color={'primary'} />
                        <Box paddingTop={2}>
                            <Typography variant={'body2'}>Cropping Image</Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        {renderImageCropArea()}
                        {renderZoomSlider()}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box display={'flex'} justify={'space-between'} flexDirection={'row'}>
                    <Button
                        onClick={onImageCropCancel}
                        variant={'contained'}
                        color={'secondary'}
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Box paddingX={1} />
                    <Button
                        onClick={onFinishClick}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        disabled={isCropping}
                    >
                        Done
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

ImageCropperDialogue.propTypes = {
    classes: PropTypes.object.isRequired,
    cropCandidate: PropTypes.object,
    onImageCropped: PropTypes.func.isRequired,
    aspect: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    onImageCropCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageCropperDialogue);
