const styles = (theme) => ({
    listContainer: {
        padding: 0,
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(3)}px)`,
        zIndex: 3,
    },
});

export default styles;
