import React from 'react';
import { InputBase, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const NewsLetterTextField = (props) => {
    const { classes, ...rest } = props;

    return (
        <InputBase
            className={classes.footerTextField}
            classes={{
                root: classes.footerTextField,
                focused: classes.focusedFooterTextField,
            }}
            {...rest}
        />
    );
};

NewsLetterTextField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsLetterTextField);
