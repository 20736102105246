const styles = (theme) => ({
    shareButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    contentCopyButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.branding.elevator_grey_light,
    },
    smallButton: {
        width: '32px',
        height: '32px',
    },
    largeButton: {
        width: '50px',
        height: '50px',
    },
});

export default styles;
