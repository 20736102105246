import React, { useMemo } from 'react';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import fetch from 'isomorphic-unfetch';
import { createUploadLink } from 'apollo-upload-client';
import { reportError } from './errors';

let apolloClient = null;

export function withApollo(PageComponent, { ssr = true } = {}) {
    const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
        const client = useMemo(() => apolloClient || initApolloClient(apolloState), []);
        return (
            <ApolloProvider client={client}>
                <PageComponent client={client} {...pageProps} />
            </ApolloProvider>
        );
    };

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
        const displayName =
            PageComponent.displayName || PageComponent.name || 'Component';

        if (displayName === 'App') {
            console.warn('This withApollo HOC only works with PageComponents.');
        }

        WithApollo.displayName = `withApollo(${displayName})`;
    }

    // Allow Next.js to remove getInitialProps from the browser build
    if (typeof window === 'undefined') {
        if (ssr) {
            WithApollo.getInitialProps = async (ctx) => {
                const { AppTree } = ctx;

                let pageProps = {};
                if (PageComponent.getInitialProps) {
                    pageProps = await PageComponent.getInitialProps(ctx);
                }

                // Run all GraphQL queries in the component tree
                // and extract the resulting data
                const apolloClient = initApolloClient();

                try {
                    // Run all GraphQL queries
                    await require('@apollo/react-ssr').getDataFromTree(
                        <AppTree
                            pageProps={{
                                ...pageProps,
                                apolloClient,
                            }}
                        />,
                    );
                } catch (error) {
                    // Prevent Apollo Client GraphQL errors from crashing SSR.
                    // Handle them in components via the data.error prop:
                    // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
                    reportError(error, {
                        severity: 'error',
                    });
                }

                // getDataFromTree does not call componentWillUnmount
                // head side effect therefore need to be cleared manually
                Head.rewind();

                // Extract query data from the Apollo store
                const apolloState = apolloClient.cache.extract();

                return {
                    ...pageProps,
                    apolloState,
                };
            };
        }
    }

    return WithApollo;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(initialState) {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (typeof window === 'undefined') {
        return createApolloClient(initialState);
    }

    // Reuse client on the client-side
    if (!apolloClient) {
        apolloClient = createApolloClient(initialState);
    }

    return apolloClient;
}

function createApolloClient(initialState = {}) {
    const isBrowser = typeof window !== 'undefined';

    const httpUploadLink = createUploadLink({
        uri: process.env.apiUri, // 'http://localhost:3002/api',
        credentials: 'include',
        fetch: !isBrowser ? fetch : undefined,
    });

    return new ApolloClient({
        connectToDevTools: isBrowser,
        ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
        link: httpUploadLink,
        cache: new InMemoryCache().restore(initialState),
    });
}
