const styles = (theme) => ({
    togglePasswordsButton: {
        color: theme.palette.legacyColors.darkInputColors.input,
        fontSize: theme.typography.pxToRem(12),
    },
    toggleEyeIcon: {
        paddingRight: theme.spacing(1),
    },
    lightVariantText: {
        color: theme.palette.primary.main,
    },
});

export default styles;
