const styles = (theme) => ({
    // Desktop
    desktopHeader: {
        display: 'block',
        // Hidden on smaller screens
        [theme.breakpoints.down(1000)]: {
            display: 'none',
        },
    },
    desktopAppbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        height: theme.static_component_sizes.desktop_appbar_height,
    },
    headerToolbarContainer: {
        flexGrow: 1,
        width: '100%',
    },
    desktopTopBrandLogoContainer: {
        minWidth: '180x',
    },
    desktopSearchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    userAvatar: {
        borderRadius: '8%',
        backgroundColor: theme.palette.branding.elevator_grey_light,
    },

    // Mobile
    mobileHeader: {
        display: 'block',
        // Hidden on larger screens
        [theme.breakpoints.up(1000)]: {
            display: 'none',
        },
    },
    // Searchbar
    searchbarRootContainer: {
        height: '50px',
        display: 'flex',
        maxWidth: '150px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    inputStyling: {
        color: theme.palette.common.white,
        width: '100%',
    },
    root: {
        //position: 'absolute',
        flexGrow: 1,
        zIndex: 3,
        width: '100%',
    },
    mobileAppBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        height: theme.static_component_sizes.mobile_appbar_height,
    },
    toolbar: {
        width: '100%',
    },
    headerLogoContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    collapseMenuContainer: {
        //position: 'fixed',
        //paddingTop: theme.static_component_sizes.mobile_appbar_height,
        width: '100%',
        zIndex: 3,
    },
    listMenuContainer: {
        width: '100%',
        backgroundColor: theme.palette.branding.elevator_grey_mid,
    },
    list: {
        padding: 0,
    },
    userOptionsList: {
        padding: 0,
        //xbackgroundColor: theme.palette.branding.elevator_grey,
        paddingTop: theme.spacing(1),
    },
    listItem: {
        color: 'white',
        textTransform: 'uppercase',
        //fontSize: '8px', //theme.typography.pxToRem(12),
        '&:hover': {
            color: 'red',
            backgroundColor: 'transparent',
            cursor: 'pointer',
        },
    },
    listItemHighEmphasis: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.branding.elevator_grey,
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
            cursor: 'pointer',
        },
    },
    listDivider: {
        background: 'rgba(255, 255, 255, 0.08)',
    },
    mobileList: {
        paddingLeft: theme.spacing(2),
    },
    disabledHover: {
        '&:hover': {
            color: 'white',
            backgroundColor: 'transparent',
        },
    },
    listIcon: {
        color: 'rgba(255, 255, 255, 0.25)',
        paddingLeft: theme.spacing(1),
    },
});

export default styles;
