import { withStyles } from '@material-ui/styles';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import styles from './styles';
import { signOut } from '../../lib/auth';
import { reportError } from '../../lib/errors';
import Router from 'next/router';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import { withSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/react-hooks';

const Layout = (props) => {
    const { classes, enqueueSnackbar, hydrateUser } = props;

    const apolloClient = useApolloClient();

    const handleSignoutClick = async () => {
        const successful = await signOut();

        try {
            await apolloClient.clearStore();
        } catch (err) {
            reportError(
                new Error(
                    'An error ocurred while clearing apollo client storage on signout',
                ),
                {
                    severity: 'error',
                },
            );
        }

        if (successful) {
            await hydrateUser();

            window.location.replace(NAVIGATION_ROUTES.SIGN_IN.uri);
        } else {
            reportError(new Error('An error ocurred while user out'), {
                severity: 'error',
            });

            enqueueSnackbar(`An error ocurred while signing you out`, {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
                preventDuplicate: true,
            });
        }
    };

    return (
        <React.Fragment>
            <div className={classes.desktopHeader}>
                <DesktopHeader {...props} handleSignoutClick={handleSignoutClick} />
            </div>
            <div className={classes.mobileHeader}>
                <MobileHeader {...props} handleSignoutClick={handleSignoutClick} />
            </div>
        </React.Fragment>
    );
};

const StyledComponent = withStyles(styles)(Layout);
export default withSnackbar(StyledComponent);
