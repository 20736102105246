import React from 'react';
import { LinearProgress, Typography, withStyles, IconButton } from '@material-ui/core';
import { ArrowLeft } from 'mdi-material-ui';
import { GridContainer, GridItem } from '../Grid';
import PropTypes from 'prop-types';
import styles from './styles';

const MobileStepper = (props) => {
    const { classes, onBackPress, activeStep, stepLabels } = props;

    const progressPercentage = Math.ceil((activeStep / (stepLabels.length - 1)) * 100);

    const getActiveStepLabel = () => {
        return stepLabels[activeStep];
    };

    return (
        <div className={classes.mobileStepperContainer}>
            <GridContainer direction={'row'} className={classes.mobileStepController}>
                {activeStep !== 0 && (
                    <GridItem className={classes.backButton}>
                        <IconButton
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            onClick={onBackPress}
                            className={classes.disableBorderRadius}
                        >
                            <ArrowLeft className={classes.arrowIcon} />
                        </IconButton>
                    </GridItem>
                )}

                <GridItem>
                    <Typography className={classes.headerText}>
                        Step {activeStep + 1} of {stepLabels.length} —{' '}
                        <em>{getActiveStepLabel()}</em>
                    </Typography>
                </GridItem>
            </GridContainer>
            <LinearProgress variant={'determinate'} value={progressPercentage} />
        </div>
    );
};

MobileStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    stepLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderStepContent: PropTypes.func.isRequired,
    onBackPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(MobileStepper);
