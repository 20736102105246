import React from 'react';
import {
    Box,
    ButtonBase,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Router from 'next/router';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import SUBMISSION_STATUSES from '../../config/SubmissionStatuses';
import SubmissionStatusChip from '../SubmissionStatusChip';
import { formatDate, interpolateSubmissionType } from '../../lib/util';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

const SubmissionsList = (props) => {
    const { classes, submissions } = props;

    const onSeeMoreClick = (orderNumber) => {
        Router.push(NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS.route + '/' + orderNumber);
    };

    const renderSubmissionListItem = (submission, index) => {
        const {
            contentTitle,
            status,
            createdAt,
            submissionType,
            orderNumber,
        } = submission;

        return (
            <ListItem
                key={index}
                component={ButtonBase}
                onClick={() => onSeeMoreClick(orderNumber)}
                className={clsx(classes.listItem, index !== 0 && classes.listItemMargin)}
            >
                <ListItemText
                    primary={
                        <Typography variant={'subtitle1'}>{contentTitle}</Typography>
                    }
                    secondary={formatDate(createdAt)}
                />

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-end'}
                >
                    <ListItemText
                        primary={
                            <Typography align={'right'} variant={'body2'}>
                                {interpolateSubmissionType(submissionType)}
                            </Typography>
                        }
                        secondary={
                            <Typography component={'span'}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'flex-start'}
                                    alignItems={'flex-end'}
                                >
                                    <SubmissionStatusChip
                                        variant={
                                            SUBMISSION_STATUSES[status.toUpperCase()]
                                        }
                                    />
                                </Box>
                            </Typography>
                        }
                    />
                </Box>
            </ListItem>
        );
    };

    return (
        <List disablePadding>
            {submissions.map((item, index) => {
                return renderSubmissionListItem(item, index);
            })}
        </List>
    );
};

SubmissionsList.propTypes = {
    classes: PropTypes.object.isRequired,
    submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(SubmissionsList);
