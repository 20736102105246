import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grow,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Camera, Close } from 'mdi-material-ui';
import { GridContainer, GridItem } from '../Grid';
import PropTypes from 'prop-types';
import styles from './styles';
import { AuthImageSrc } from '../../config/staticAssets';

const ImageUploader = (props) => {
    const {
        classes,
        error,
        helperText,
        inputProps,
        file,
        onRemoveImage,
        label,
        variant,
        avatarName,
        disabled,
    } = props;

    const getImageURL = () => {
        if (file instanceof Blob) return URL.createObjectURL(file);
        else if (Object.keys(file).includes('sourceUrl')) return file.sourceUrl;
        else return null;
    };

    const getSourceSet = () => {
        if (Object.keys(file).includes('sourceSet')) return file.sourceSet;
        return null;
    };

    const renderAvatarVariant = () => {
        return (
            <>
                <Box className={classes.avatarContainer}>
                    <Box
                        position={'absolute'}
                        bottom={0}
                        className={classes.gradientFade}
                    />
                    {file && (
                        <Grow in>
                            <>
                                <img
                                    src={getImageURL()}
                                    srcSet={getSourceSet()}
                                    className={classes.avatar}
                                />
                                {renderRemoveButton()}
                            </>
                        </Grow>
                    )}
                    {!file && (
                        <Grow in>
                            <Avatar className={classes.avatar}>{avatarName}</Avatar>
                        </Grow>
                    )}
                    {renderUploadButton()}
                </Box>
                <Box paddingTop={1}>
                    <Typography
                        color={'textSecondary'}
                        variant={'caption'}
                        className={clsx(
                            classes.imageUploadsDisclaimer,
                            error && classes.errorText,
                        )}
                    >
                        {helperText}
                    </Typography>
                </Box>
            </>
        );
    };

    const renderContentVariant = () => {
        return (
            <>
                <Typography variant={'subtitle2'}>{label}</Typography>
                <GridContainer direction={'row'} spacing={1} alignItems={'center'}>
                    <GridItem>
                        <Button
                            component={'label'}
                            variant={'contained'}
                            fullWidth
                            size={'small'}
                            color={'primary'}
                        >
                            Choose Image
                            <input
                                accept="image/*"
                                type={'file'}
                                style={{ display: 'none' }}
                                {...inputProps}
                            />
                        </Button>
                    </GridItem>
                    <GridItem>
                        <Typography variant={'caption'} className={classes.fileName}>
                            {file ? file.name : 'No image chosen'}
                        </Typography>
                        {file && (
                            <Tooltip title={'Remove this image'}>
                                <IconButton
                                    size={'small'}
                                    color={'error'}
                                    onClick={onRemoveImage}
                                >
                                    <Close color={'error'} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </GridItem>
                </GridContainer>

                <Typography
                    color={'textSecondary'}
                    variant={'caption'}
                    className={clsx(
                        classes.imageUploadsDisclaimer,
                        error && classes.errorText,
                    )}
                >
                    {helperText}
                </Typography>

                {file && (
                    <Grow in>
                        <div className={classes.imageContainer}>
                            <img src={getImageURL()} width={'50%'} height={'auto'} />
                        </div>
                    </Grow>
                )}
            </>
        );
    };

    const renderUploadButton = () => {
        if (disabled) return null;
        return (
            <IconButton
                component={'label'}
                variant={'contained'}
                size={'small'}
                className={classes.iconButton}
            >
                <Camera />
                <input
                    accept="image/*"
                    type={'file'}
                    style={{ display: 'none' }}
                    {...inputProps}
                />
            </IconButton>
        );
    };

    const renderRemoveButton = () => {
        if (disabled) return null;
        return (
            <Tooltip title={'Remove this image'}>
                <IconButton
                    component={'label'}
                    variant={'contained'}
                    size={'small'}
                    className={classes.removeButton}
                    onClick={onRemoveImage}
                >
                    <Close />
                </IconButton>
            </Tooltip>
        );
    };

    const renderBannerVariant = () => {
        return (
            <Box width={'100%'}>
                <Box className={classes.bannerContainer}>
                    <Box
                        position={'absolute'}
                        bottom={0}
                        className={classes.gradientFade}
                    />
                    {file && (
                        <>
                            <img
                                src={getImageURL()}
                                srcSet={getSourceSet()}
                                className={classes.banner}
                            />
                            {renderRemoveButton()}
                        </>
                    )}

                    {!file && <img {...AuthImageSrc} className={classes.banner} />}
                    {renderUploadButton()}
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                    <Typography
                        color={'textSecondary'}
                        variant={'caption'}
                        className={clsx(
                            classes.imageUploadsDisclaimer,
                            error && classes.errorText,
                        )}
                    >
                        {helperText}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const interpolateUploaderVariant = () => {
        switch (variant) {
            case 'avatar':
                return renderAvatarVariant();
            case 'content':
                return renderContentVariant();
            case 'banner':
                return renderBannerVariant();
            default:
                console.error('attempted to render unknown image uploader: ' + variant);
                break;
        }
    };

    return interpolateUploaderVariant();
};

ImageUploader.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    inputProps: PropTypes.object,
    file: PropTypes.object,
    onRemoveImage: PropTypes.func.isRequired,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['content', 'avatar', 'banner']),
    avatarName: PropTypes.string,
    disabled: PropTypes.bool,
};

ImageUploader.defaultProps = {
    label: 'Image',
    variant: 'content',
    avatarName: 'E',
    disabled: false,
};

export default withStyles(styles)(ImageUploader);
