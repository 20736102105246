const styles = (theme) => ({
    /***************
     * ACTION AREA *
     ***************/
    actionAreaContainer: {
        display: 'flex',
        padding: theme.spacing(4, 2, 2, 2),
        width: '100%',
    },
    alignRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignColumn: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alignRowReverse: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignColumnReverse: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',
    },

    /***********************
     * CARD SECTION HEADER *
     ***********************/
    cardSectionHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '40px',
    },
});

export default styles;
