import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { DOMAIN_BASE, NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import LegacyNavLink from '../LegacyNavLink';
import PropTypes from 'prop-types';
import styles from './styles';
import { formatDate } from '../../lib/util';

const ArticleCard = (props) => {
    const { classes, postData } = props;

    const { categories, modifiedAt, authors, title, slug, image } = postData;
    const category = categories.length ? categories[0] : null;
    const author = authors[0];
    const postUrl = DOMAIN_BASE + '/' + slug;

    return (
        <Paper className={classes.disablePaperMargins}>
            <a href={postUrl}>
                <img
                    src={
                        image
                            ? image.sourceUrl
                            : 'https://elevator-media.imgix.net/static/SEO_banner.png?w=400&h=300&ixlib=js-2.1.2&s=5b3e04cf0d4128c3877724435a3b26d3'
                    }
                    className={classes.thumbnail}
                />
            </a>
            <Box padding={4}>
                <Box display={'flex'} flexDirection={'column'}>
                    {category && (
                        <LegacyNavLink
                            href={DOMAIN_BASE + '/category/' + category.slug}
                            color={'red'}
                        >
                            {category.name}
                        </LegacyNavLink>
                    )}
                    <Typography
                        variant={'caption'}
                        color={'textSecondary'}
                        component={'span'}
                        className={classes.metadataText}
                    >
                        {formatDate(modifiedAt)} | by{' '}
                        <Typography
                            component={'a'}
                            variant={'caption'}
                            color={'textSecondary'}
                            href={
                                NAVIGATION_ROUTES.USERS.route +
                                '/' +
                                author.preferredUsername
                            }
                            className={classes.authorLink}
                        >
                            {author.displayName || author.preferredUsername}
                        </Typography>
                    </Typography>
                </Box>
                <Box paddingY={4}>
                    <Typography
                        variant={'h5'}
                        component={'a'}
                        href={postUrl}
                        className={classes.postTitle}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

ArticleCard.propTypes = {
    classes: PropTypes.object.isRequired,
    postData: PropTypes.shape({
        category: PropTypes.string.isRequired,
        modifiedAt: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        thumbnail: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(ArticleCard);
