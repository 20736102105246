import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const NotificationBox = (props) => {
    const { classes, children } = props;

    return <div className={classes.attentionBox}>{children}</div>;
};

NotificationBox.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
};

export default withStyles(styles)(NotificationBox);
