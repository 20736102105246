import React from 'react';
import {
    MenuItem,
    Paper,
    withStyles,
    TextField,
    Typography,
    Avatar,
    ListItemAvatar,
    ListItemText,
    InputAdornment,
    Button,
} from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Autosuggest from 'react-autosuggest';
import { Search } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_USERS } from '../../lib/queries/users';
import PropTypes from 'prop-types';
import styles from './styles';

const AutoSuggestInput = (props) => {
    const {
        classes,
        suggestionSrc, // Source array of suggestion from which to query from.
        label, // label for the text field input,
        onUserSelected,
        textFieldInput,
        setTextFieldInput,
    } = props;

    const [inputHasFocus, setInputHasFocus] = React.useState(false);

    // Search Query
    const { data, refetch } = useQuery(SEARCH_USERS, {
        variables: {
            input: '',
        },
    });

    let UsernameSuggestions = data && data.searchUsers ? data.searchUsers : [];

    /**
     * Renders a single list item based on the list of generated suggestions
     */
    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const matches = match(suggestion.preferredUsername, query);
        const parts = parse(suggestion.preferredUsername, matches);

        let avatarSrc = {};

        if (suggestion.avatar)
            avatarSrc = {
                src: suggestion.avatar.sourceUrl,
                srcSet: suggestion.avatar.sourceSet,
            };

        return (
            <MenuItem
                selected={isHighlighted}
                // prevent the click causing the input to be blurred
                onMouseDown={(e) => e.preventDefault()}
                component={'div'}
            >
                <ListItemAvatar>
                    <Avatar {...avatarSrc}>{suggestion.preferredUsername[0]}</Avatar>
                </ListItemAvatar>
                {/**
                    Renderrs the text-editing distance in bold, and completion in 
                    default text.
                 */}
                <ListItemText
                    primary={parts.map((part, index) => {
                        return part.highlight ? (
                            <Typography
                                key={String(index)}
                                component={'span'}
                                className={classes.editDistanceText}
                            >
                                {part.text}
                            </Typography>
                        ) : (
                            <Typography
                                variant={'body2'}
                                key={String(index)}
                                component={'span'}
                            >
                                {part.text}
                            </Typography>
                        );
                    })}
                    secondary={suggestion.stageName}
                />
            </MenuItem>
        );
    };

    /**
     * Utility function, retrieves the name attribute from a suggestion object.
     */
    const getSuggestionValue = (suggestion) => {
        return suggestion.preferredUsername;
    };

    /**
     * Generates a list of suggestions from user input.
     */
    const getSuggestions = (userInput, suggestionCountCap = 5) => {
        // Normalize the user input
        const inputValue = userInput.trim().toLowerCase();
        const inputLength = inputValue.length;

        // Keeps track of suggestions count to make sure we only return a specific amount.
        let generatedSuggestionsCount = 0;

        const suggestionRes =
            inputLength === 0
                ? []
                : suggestionSrc.filter((suggestion) => {
                      const keep =
                          generatedSuggestionsCount < suggestionCountCap &&
                          suggestion.preferredUsername
                              .toLowerCase()
                              .slice(0, inputLength) === inputValue;

                      if (keep) {
                          generatedSuggestionsCount += 1;
                      }

                      return keep;
                  });

        return suggestionRes;
    };

    const handletextFieldInputChange = (event, { newValue }) => {
        setTextFieldInput(newValue);
    };

    const handleSuggestionSelected = (e, { suggestionValue }) => {
        e.preventDefault();
        onUserSelected(
            data.searchUsers.find(
                (values) => values.preferredUsername === suggestionValue,
            ),
        );
    };

    /**
     * Renders the input component. In our case it is a chip input
     */
    const renderInput = ({ value, onChange, ref, ...other }) => {
        return (
            <TextField
                value={value}
                label={label}
                onChange={onChange}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <div className={classes.searchIcon}>
                                <Search color={'action'} fontSize={'small'} />
                            </div>
                        </InputAdornment>
                    ),
                }}
                {...other}
            />
        );
    };

    const renderSuggestionContainer = ({ containerProps, children, query }) => {
        // There are results to show
        if (children !== null && inputHasFocus) {
            return (
                <Paper elevation={3} {...containerProps}>
                    {children}
                </Paper>
            );
        } else if (children === null && inputHasFocus && query.length !== 0) {
            // There are no results to show but we want to tell the user
            return (
                <Paper
                    elevation={3}
                    {...containerProps}
                    className={classes.noResultsFoundContainer}
                >
                    <Typography variant={'caption'}>
                        No results found for "{query}." Why not try adding them manually?
                    </Typography>
                </Paper>
            );
        } else {
            // Render nothing
            return null;
        }
    };

    return (
        <Autosuggest
            theme={{
                container: classes.container,
                suggestionsContainer: classes.suggestionsContainer,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderInputComponent={renderInput}
            suggestions={UsernameSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            // What should happen when the user selects a value from the
            // list of suggestions.
            onSuggestionSelected={handleSuggestionSelected}
            focusInputOnSuggestionClick={false}
            highlightFirstSuggestion
            inputProps={{
                value: textFieldInput,
                onChange: handletextFieldInputChange,
                onBlur: () => {
                    setInputHasFocus(false);
                },
                onFocus: () => setInputHasFocus(true),
                ...props,
            }}
            renderSuggestionsContainer={renderSuggestionContainer}
            onSuggestionsFetchRequested={({ value, reason }) => {
                if (value.length >= 3) {
                    refetch({ input: value });
                }
            }}
        />
    );
};

AutoSuggestInput.propTypes = {
    classes: PropTypes.object.isRequired,
    suggestionSrc: PropTypes.arrayOf(
        PropTypes.shape({
            preferredUsername: PropTypes.string.isRequired,
            stageName: PropTypes.string.isRequired,
        }),
    ).isRequired,
    label: PropTypes.string.isRequired,
    onUserSelected: PropTypes.func.isRequired,
};

export default withStyles(styles)(AutoSuggestInput);
