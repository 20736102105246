import React from 'react';
import { InputBase, withStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const DarkTextField = (props) => {
    const { classes, auxillaryInputProps, ...rest } = props;

    return (
        <TextField
            InputLabelProps={{
                classes: {
                    root: classes.inputLabelRoot,
                    focused: classes.inputLabelFocused,
                },
                shrink: true,
            }}
            InputProps={{
                classes: {
                    input: classes.input,
                    root: classes.inputRoot,
                    focused: classes.inputRootFocused,
                    disabled: classes.inputRootDisabled,
                    error: classes.inputError,
                },
                disableUnderline: true,
                ...auxillaryInputProps,
            }}
            {...rest}
        />
    );
};

DarkTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    auxillaryInputProps: PropTypes.object,
};

export default withStyles(styles)(DarkTextField);
