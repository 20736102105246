import React from 'react';
import { Step, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

const DesktopStepper = (props) => {
    const { classes, activeStep, stepLabels } = props;

    const stepIsActive = (currentStep) => {
        return activeStep === currentStep;
    };

    return (
        <div className={classes.desktopStepperContainer}>
            <Stepper activeStep={activeStep} className={classes.DesktopStepper}>
                {stepLabels.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>
                                <Typography
                                    className={clsx(
                                        classes.desktopStepperLabelInactive,
                                        stepIsActive(index) &&
                                            classes.desktopStepperLabelAvtive,
                                    )}
                                >
                                    {label}
                                </Typography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
};

DesktopStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    stepLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    onBackPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(DesktopStepper);
