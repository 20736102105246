import React from 'react';
import {
    Divider,
    IconButton,
    List,
    Paper,
    ListItem,
    ListItemText,
    Typography,
    withStyles,
} from '@material-ui/core';
import { DeleteOutline, PencilOutline } from 'mdi-material-ui';
import { GridContainer, GridItem } from '../../components';
import PropTypes from 'prop-types';
import styles from './styles';

const CreditsList = (props) => {
    const { classes, credits, removeCredit, editCredit } = props;

    const renderListItems = () => {
        return credits.map((item, index, collection) => {
            const { name, type } = item;
            return (
                <div key={index}>
                    <ListItem alignItems="center" className={classes.listItem}>
                        <ListItemText
                            primary={
                                <Typography variant="subtitle1" color="textPrimary">
                                    {name}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="body2" color="textSecondary">
                                    {type}
                                </Typography>
                            }
                        />
                        <div>
                            <GridContainer spacing={2} direction={'row'}>
                                <GridItem>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => removeCredit(index)}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </GridItem>
                                <GridItem>
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => editCredit(index)}
                                    >
                                        <PencilOutline />
                                    </IconButton>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </ListItem>
                    {index !== collection.length - 1 && (
                        <Divider className={classes.listItemSep} />
                    )}
                </div>
            );
        });
    };

    return (
        <Paper classes={{ root: classes.listContainer }}>
            {credits.length >= 1 && <List>{renderListItems()}</List>}
            {credits.length === 0 && (
                <div className={classes.emptyStateContainer}>
                    <Typography variant={'body1'} color={'textSecondary'}>
                        Tap the '+' icon to add a new credit
                    </Typography>
                </div>
            )}
        </Paper>
    );
};

CreditsList.propTypes = {
    classes: PropTypes.object.isRequired,
    credits: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
    removeCredit: PropTypes.func.isRequired,
    editCredit: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreditsList);
