import React from 'react';
import AutoSuggestVariant from './AutoSuggestVariant';
import PropTypes from 'prop-types';

const Layout = (props) => {
    const { typeVariant, ...rest } = props;

    //TODO: implement full search variant feature here
    if (typeVariant === 'autoComplete') {
        return <AutoSuggestVariant {...rest} />;
    } else {
        return null;
    }
};

Layout.propTypes = {
    typeVariant: PropTypes.oneOf(['autoComplete', 'search']),
};

export default Layout;
