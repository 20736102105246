import React from 'react';
import { InputAdornment, InputBase, withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import styles from './styles';

const SearchInput = (props) => {
    const { classes, searchInput, handleSearchInput, onSubmit, iconEdge } = props;

    /**
     * Interpolates the correct propnames for displaying the search icon on either
     * the start or end edge of the search input baed on props
     */
    const interpolateAdornmentProps = () => {
        let adornmentProps = {};

        const adornment = (
            <InputAdornment position="start">
                <Search fontSize={'small'} />
            </InputAdornment>
        );

        if (iconEdge === 'start') {
            adornmentProps.startAdornment = adornment;
        } else {
            adornmentProps.endAdornment = adornment;
        }

        return adornmentProps;
    };

    return (
        <div className={classes.searchbarRootContainer}>
            <form onSubmit={onSubmit} className={classes.inputStyling}>
                <InputBase
                    value={searchInput}
                    onChange={handleSearchInput}
                    className={classes.inputStyling}
                    {...interpolateAdornmentProps()}
                />
            </form>
        </div>
    );
};

SearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
    searchInput: PropTypes.string.isRequired,
    handleSearchInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    iconEdge: PropTypes.oneOf(['start', 'end']),
};

SearchInput.defaultProps = {
    iconEdge: 'start',
};

export default withStyles(styles)(SearchInput);
