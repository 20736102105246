import React from 'react';
import { withStyles } from '@material-ui/styles';
import MobileStepper from './MobileStepper';
import DesktopStepper from './DesktopStepper';
import PropTypes from 'prop-types';
import styles from './styles';

const Layout = (props) => {
    const { classes, renderStepContent, activeStep } = props;

    return (
        <React.Fragment>
            <div className={classes.desktopStepper}>
                <DesktopStepper {...props} />
            </div>
            <div className={classes.mobileStepper}>
                <MobileStepper {...props} />
            </div>
            <div className={classes.stepperContentContainer}>
                <div className={classes.stepperCard}>{renderStepContent(activeStep)}</div>
            </div>
        </React.Fragment>
    );
};

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    renderStepContent: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired,
};

export default withStyles(styles)(Layout);
