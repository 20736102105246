const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
    },
    screenContentOffset: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        minHeight: `calc(100% - ${theme.static_component_sizes.desktop_appbar_height})`,
        top: theme.static_component_sizes.desktop_appbar_height,
        // Styles for mobile
        [theme.breakpoints.down(1000)]: {
            position: 'static',
            backgroundColor: theme.palette.background.default,
            minHeight: `calc(100% - ${theme.static_component_sizes.mobile_appbar_height})`,
            top: 0, //theme.static_component_sizes.mobile_appbar_height,
        },
        backgroundColor: theme.palette.background.default,
    },
    notificationBox: {
        top: theme.static_component_sizes.desktop_appbar_height,
        // Styles for mobile
        [theme.breakpoints.down(1000)]: {
            position: 'static',
            top: 0, //theme.static_component_sizes.mobile_appbar_height,
            padding: theme.spacing(2),
        },
        backgroundColor: theme.palette.common.lightFadedBlue,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        minHeight: theme.static_component_sizes.notificationBoxHeight,
        zIndex: 2,
    },
    notificationBoxOffset: {
        top: `calc(${theme.static_component_sizes.desktop_appbar_height} + ${theme.static_component_sizes.notificationBoxHeight})`,
        // Styles for mobile
        [theme.breakpoints.down(1000)]: {
            top: 0, //theme.static_component_sizes.mobile_appbar_height,
        },
    },
    message: {
        color: theme.palette.primary.main,
    },
    verificationLink: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
});

export default styles;
