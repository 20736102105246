const styles = (theme) => ({
    feedbackRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.text.primary,
    },
    feedbackIconContainer: {
        paddingTop: '4px',
        paddingRight: '4px',
    },
    checkmark: {
        fontSize: theme.typography.pxToRem(12),
    },
    positiveFeedback: {
        color: theme.palette.passwordFeedback.success,
        fontWeight: 'bold',
    },
    notSatisfiedFeedback: {
        color: theme.palette.text.secondary,
    },
});

export default styles;
