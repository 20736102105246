const styles = (theme) => ({
    root: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
    calloutBox: {
        backgroundColor: theme.palette.common.lightFadedBlue,
        width: '100%',
        padding: theme.spacing(2),
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    calloutTextIcon: {
        color: theme.palette.primary.main,
    },
    calloutTextSpacing: {
        marginLeft: theme.spacing(2),
    },
});

export default styles;
