import React, { Component } from 'react';
import Router from 'next/router';
import { isAuthenticated } from '../../lib/auth';
import { withApollo } from '../../lib/apollo';
import { MY_USER_AND_PERMISSIONS } from '../../lib/queries/users';
import { CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import { reportError } from '../../lib/errors';
import { encodeFromURL } from '../../lib/redirects';

/**
 * HOC wrapper around authenticated component routes.
 *
 * Inspired by:
 * https://github.com/zeit/next.js/issues/153
 */
export default function withAuth(
    AuthenticatedComponent,
    requiresAuth = false,
    shouldNav = false,
) {
    class withAuth extends Component {
        constructor(props) {
            super(props);
            this.state = {
                renderComponent: false,
                user: null,
            };
        }

        async componentDidMount() {
            await this.hydrateUser(false);
        }

        hydrateUser = async (useCache = true) => {
            const isLoggedIn = await isAuthenticated();
            let user = null;

            if (isLoggedIn) {
                const { client } = this.props;

                try {
                    const { data } = await client.query({
                        query: MY_USER_AND_PERMISSIONS,
                        fetchPolicy: useCache ? 'cache-first' : 'network-only',
                    });

                    user = {
                        profile: data.myUser,
                    };
                } catch (err) {
                    reportError(err, {
                        metaData: {
                            operation: 'query MY_USER_AND_PERMISSIONS',
                        },
                    });
                }
            }

            if (!user && requiresAuth) {
                const requestedRedirect = window.location.href;
                const b64EncodedFromURL = encodeFromURL(requestedRedirect);

                if (b64EncodedFromURL) {
                    window.location.replace(
                        `${NAVIGATION_ROUTES.SIGN_IN.uri}?from=${b64EncodedFromURL}`,
                    );
                } else {
                    window.location.replace(NAVIGATION_ROUTES.SIGN_IN.uri);
                }
            } else if (user && !requiresAuth && shouldNav) {
                Router.push(NAVIGATION_ROUTES.ROOT.route);
            } else
                this.setState({
                    renderComponent: true,
                    user,
                });
        };

        renderLoading() {
            const { classes } = this.props;
            return (
                <div className={classes.loadingRoot}>
                    <CircularProgress />
                </div>
            );
        }

        renderContent() {
            const { client } = this.props;
            return (
                <AuthenticatedComponent
                    currentUser={this.state.user}
                    hydrateUser={this.hydrateUser}
                    client={client}
                />
            );
        }

        render() {
            return !this.state.renderComponent
                ? this.renderLoading()
                : this.renderContent();
        }
    }

    const StyledWithAuth = withStyles(styles)(withAuth);

    return withApollo(StyledWithAuth);
}
