import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavigationHeader, Footer, Link } from '../../components';
import { Box, Typography } from '@material-ui/core';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import styles from './styles';
import { CookieDisclaimer } from '../../components';

const useStyles = makeStyles(styles);

const withNavWrapper = (Component) => (props) => {
    // Use a more imperative approve to styling here to reduce amount of HOC wrappers
    const classes = useStyles();
    const { currentUser } = props;

    const router = useRouter();

    const shouldRenderNotification = () => {
        const noEmailVerified = currentUser && !currentUser.profile.emailVerified;
        const notOnVerifyRoute = router.pathname !== NAVIGATION_ROUTES.VERIFY_EMAIL.route;
        return noEmailVerified && notOnVerifyRoute;
    };

    const renderEmailVerificationBox = () => {
        if (!shouldRenderNotification()) return null;

        return (
            <Box className={classes.notificationBox}>
                <Typography
                    variant={'body2'}
                    className={classes.message}
                    component={'span'}
                >
                    Your email has not been verified yet! click{' '}
                    <Link
                        className={classes.verificationLink}
                        href={NAVIGATION_ROUTES.VERIFY_EMAIL.route}
                    >
                        here
                    </Link>{' '}
                    to verify
                </Typography>
            </Box>
        );
    };

    return (
        <div className={classes.root}>
            <NavigationHeader {...props} />
            {renderEmailVerificationBox()}
            <div
                className={clsx(
                    classes.screenContentOffset,
                    shouldRenderNotification() && classes.notificationBoxOffset,
                )}
            >
                <Component {...props} />
                <Footer />
            </div>
            <CookieDisclaimer />
        </div>
    );
};

export default withNavWrapper;
