const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '18px',
    },
    menuBarBase: {
        backgroundColor: theme.palette.common.white,
        width: '24px',
        height: '2px',
        borderRadius: '10px',
        transition: '0.6s ease',
        transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.29, 1.01)',
    },

    //Animated Styles
    animatedTopBar: {
        '-webkit-transform': 'translate(0px, 8px) rotate(45deg)',
        transform: 'translate(0px, 8px) rotate(45deg)',
    },
    animatedMidBar: {
        opacity: 0,
    },
    animatedBottomBar: {
        '-webkit-transform': 'translate(0px, -8px) rotate(-225deg)',
        transform: 'translate(0px, -8px) rotate(-225deg)',
    },
});

export default styles;
