import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem, ListItemText, Paper } from '@material-ui/core';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const LocationAutocomplete = ({ classes, type, onChange, inputProps }) => {
    const autocompletionRequest = {};
    let filterValue = null;

    if (type === 'city') {
        autocompletionRequest.types = ['(cities)'];
    } else if (['country', 'state'].includes(type)) {
        autocompletionRequest.types = ['(regions)'];

        if (type === 'country') filterValue = 'country';
        else if (type === 'state') filterValue = 'administrative_area_level_1';
    }

    const renderSuggestions = (_active, suggestions, onSelectSuggestion) => {
        let suggestionsToRender = filterValue
            ? suggestions.filter(({ types }) => types.includes(filterValue))
            : suggestions;

        return (
            <Paper elevation={4} className={classes.listContainer}>
                {suggestionsToRender.map((suggestion) => (
                    <MenuItem
                        component={'div'}
                        onClick={(event) => {
                            onSelectSuggestion(suggestion, event);
                            onChange &&
                                onChange(suggestion.structured_formatting.main_text);
                        }}
                    >
                        <ListItemText>
                            {suggestion.structured_formatting.main_text}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Paper>
        );
    };

    return (
        <GooglePlacesAutocomplete
            autocompletionRequest={autocompletionRequest}
            renderInput={(defaultInputProps) => (
                <TextField
                    {...defaultInputProps}
                    {...inputProps}
                    onChange={(event) => {
                        onChange && onChange(event.target.value);
                        defaultInputProps.onChange(event);
                    }}
                />
            )}
            renderSuggestions={renderSuggestions}
        />
    );
};

LocationAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        required: PropTypes.bool,
        fullWidth: PropTypes.bool,
        error: PropTypes.string,
        helperText: PropTypes.string,
    }).isRequired,
    type: PropTypes.oneOf(['city', 'state', 'country']).isRequired,
};

LocationAutocomplete.defaultProps = {
    inputProps: {
        required: false,
        fullWidth: false,
        error: null,
        helperText: '',
    },
};

export default withStyles(styles)(LocationAutocomplete);
