import axios from 'axios';
import passwordValidator from 'password-validator';
import zxcvbn from 'zxcvbn';

axios.defaults.withCredentials = true;

const authEndpoint =
    process.env.cookieDomain === 'localhost'
        ? 'http://localhost:3002/auth'
        : new URL(process.env.apiUri).origin + '/auth';

export const signup = async (user) => {
    const { username, email, firstName, lastName, password } = user;
    try {
        const response = await axios.post(`${authEndpoint}/signup`, {
            username,
            email,
            firstName,
            lastName,
            password,
        });

        return response;
    } catch (err) {
        throw err.response.data;
    }
};

/**
 * Utility function which either returns a boolean as to whether a use currently authenticated.
 */
export const isAuthenticated = async () => {
    try {
        await axios.post(`${authEndpoint}/me`);
        return true;
    } catch (err) {
        return false;
    }
};

/**
 * Signs a user out via Cognito. Will throw an error if the process fails.
 */
export const signOut = async () => {
    try {
        await axios.post(`${authEndpoint}/signout`);
        return true;
    } catch (err) {
        return false;
    }
};

export const requestEmailVerificationCode = async () => {
    try {
        await axios.post(`${authEndpoint}/verify`, {
            action: 'REQUEST_CODE',
        });
        return true;
    } catch (err) {
        throw err.response.data;
    }
};

export const confirmEmailVerification = async (code) => {
    try {
        await axios.post(`${authEndpoint}/verify`, {
            action: 'SUBMIT',
            code,
        });
        return true;
    } catch (err) {
        throw err.response.data;
    }
};

export const changePassword = async (currentPassword, newPassword) => {
    try {
        await axios.post(`${authEndpoint}/manage-password`, {
            action: 'CHANGE',
            currentPassword,
            newPassword,
        });
        return true;
    } catch (err) {
        throw err.response.data;
    }
};

const schema = new passwordValidator();

const allowedSpecialChars = /[!@#$%^&*(){}[\]<>?/\\|,.~`"':;_-]/;

schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()
    .has(allowedSpecialChars)
    .has()
    .not()
    .spaces();

export const isValidPassword = (input) => {
    return schema.validate(input);
};

// Only validates length min 8 characters
const lengthSchema = new passwordValidator();
lengthSchema
    .is()
    .min(8)
    .has()
    .not()
    .spaces();
export const isAtLeastEightChars = (input) => {
    return lengthSchema.validate(input);
};

// Only validates for containing at least one lowercase letter
const lowerCaseLetterSchema = new passwordValidator();
lowerCaseLetterSchema
    .has()
    .lowercase()
    .has()
    .not()
    .spaces();
export const hasLowerCaseLetter = (input) => {
    return lowerCaseLetterSchema.validate(input);
};

// Only validates for containing at least one uppercase letter
const upperCaseLetterSchema = new passwordValidator();
upperCaseLetterSchema
    .has()
    .uppercase()
    .has()
    .not()
    .spaces();
export const hasUpperCaseLetter = (input) => {
    return upperCaseLetterSchema.validate(input);
};

// Only validates for containing special character
const specialCharSchema = new passwordValidator();
specialCharSchema
    .has()
    .symbols()
    .has(allowedSpecialChars)
    .has()
    .not()
    .spaces();
export const hasSpecialCharacter = (input) => {
    return specialCharSchema.validate(input);
};

// Only validates for containing digits
const digitsSchema = new passwordValidator();
digitsSchema
    .has()
    .digits()
    .has()
    .not()
    .spaces();
export const hasDigit = (input) => {
    return digitsSchema.validate(input);
};

// Only validates that password is not too guesable
export const notTooGuessable = (input) => {
    return zxcvbn(input).score >= 2;
};
