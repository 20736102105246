const styles = (theme) => ({
    root: {
        minHeight: '350px',
        width: '100%',
        backgroundColor: theme.palette.branding.elevator_grey,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerContentContainer: {
        maxWidth: '1100px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(4),
        },

        // Debugging purposes
        // border: '4px solid red',
    },
    footerContainerLarge: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: '150px',
        },
        // Debugging purposes
        // color: 'white',
        //border: '4px solid green',
    },
    footerContainerSmall: {
        minHeight: '100px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',

        // Debugging purposes
        // color: 'white',
        // border: '4px solid green',
    },

    // New Lettter CTA:
    newsLetterCTAText: {
        color: theme.palette.legacyColors.darkInputColors.input,
        textTransform: 'uppercase',
        fontSize: theme.typography.pxToRem(25),
        fontWeight: '700',
    },
    socialMediaIcon: {
        fontSize: theme.typography.pxToRem(50),
        color: theme.palette.legacyColors.darkInputColors.input,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(35),
        },
    },

    //copright:
    copyrightNoticeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    copyrightNotice: {
        color: theme.palette.legacyColors.darkInputColors.input,
        fontSize: theme.typography.pxToRem(13),
        paddingLeft: theme.spacing(1),
    },
    footerLogo: {
        height: '20px',
        width: 'auto',
    },

    // Footer Textfields
    footerTextField: {
        backgroundColor: theme.palette.branding.elevator_grey_mid,
        maxHeight: '46px',
        width: '100%',
        color: theme.palette.legacyColors.darkInputColors.input,
        padding: theme.spacing(2),
        border: '2px solid transparent',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '500',
    },
    textFieldContainer: {
        width: '100%',
    },
    focusedFooterTextField: {
        border: `2px solid ${theme.palette.legacyColors.darkInputColors.inputFocus}`,
        borderRadius: '3px',
    },

    // footer link
    footerLinkContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export default styles;
