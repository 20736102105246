import React from 'react';
import { AppBar, Avatar, IconButton, Toolbar, withStyles, Box } from '@material-ui/core';
import { DOMAIN_BASE, NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import AccountPopover from '../AccountPopover';
import LegacyNavLink from '../LegacyNavLink';
import SearchInput from './SearchInput';
import PropTypes from 'prop-types';
import styles from './styles';

const DesktopHeader = (props) => {
    const { classes, currentUser, handleSignoutClick } = props;

    // Tracks the current search input field
    const [searchInput, setSearchInput] = React.useState('');

    // Set up react state hooks
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     * Handler function for when the user clicks the avatar. Will set the accountPopOver component's
     * anchor ref point to the avatar.
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handler for the current search input value
     */
    const handleSearchInput = (event) => {
        setSearchInput(event.target.value);
    };

    /**
     * Handler for submiting the search input from/
     * NOTE: this code is handle the legacy wp site's current search params.
     */
    const onSubmitSearchInput = (event) => {
        event.preventDefault();

        // sanitize spaces
        const sanitizedInput = searchInput.replace(/\s/g, '+');

        // Setup query parameters
        const searchURL = `${DOMAIN_BASE}/search?s=${sanitizedInput}`;

        // Let legacy WP site handler search functionality
        window.open(searchURL, '_self');
    };

    const renderNavListItem = ({ displayText, route }, index, collection) => {
        return (
            <Box paddingRight={4} key={index}>
                <LegacyNavLink href={route}>{displayText}</LegacyNavLink>
            </Box>
        );
    };

    const renderLegacyNavLinks = () => {
        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box paddingRight={2} className={classes.desktopTopBrandLogoContainer}>
                    <a href={DOMAIN_BASE}>
                        <img
                            width={'160px'}
                            src={'/static/img/elevator-header-logo.png'}
                        />
                    </a>
                </Box>
                {Object.values(NAVIGATION_ROUTES.LEGACY.HEADER).map(
                    (routeConfig, index, collection) => {
                        return renderNavListItem(routeConfig, index, collection);
                    },
                )}
            </Box>
        );
    };

    const renderAccountHandler = () => {
        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box className={classes.desktopSearchContainer}>
                    <SearchInput
                        searchInput={searchInput}
                        handleSearchInput={handleSearchInput}
                        onSubmit={onSubmitSearchInput}
                        iconEdge={'end'}
                    />
                </Box>
                {!currentUser && (
                    <Box className={classes.desktopSearchContainer}>
                        <LegacyNavLink href={NAVIGATION_ROUTES.SIGN_IN.route}>
                            Sign in
                        </LegacyNavLink>
                    </Box>
                )}
                {currentUser && (
                    <Box className={classes.desktopSearchContainer}>
                        <IconButton
                            aria-label="Account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleClick}
                            color="inherit"
                            className={classes.test}
                        >
                            <Avatar
                                className={classes.userAvatar}
                                src={
                                    currentUser.profile.avatar &&
                                    currentUser.profile.avatar.sourceUrl
                                }
                            >
                                {currentUser.profile.firstName[0].toUpperCase()}
                            </Avatar>
                        </IconButton>
                        <AccountPopover
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            userName={currentUser.profile.preferredUsername}
                            email={currentUser.profile.email}
                            avatarURL={null}
                            handleSignoutClick={handleSignoutClick}
                            isPreferred={currentUser.profile.preferred}
                            isElevatorStaff={
                                currentUser.profile.permissionGroup.groupName !==
                                'Standard'
                            }
                            isVerified={currentUser.profile.isVerified}
                        />
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <AppBar elevation={0} className={classes.desktopAppbar}>
            <Toolbar className={classes.toolbar}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={classes.headerToolbarContainer}
                >
                    {renderLegacyNavLinks()}
                    {renderAccountHandler()}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

DesktopHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSignoutClick: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
};

export default withStyles(styles)(DesktopHeader);
