import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const CardSectionHeader = (props) => {
    const { classes, sectionTitle, children } = props;

    return (
        <div className={classes.cardSectionHeaderContainer}>
            <Typography variant={'caption'} color={'textSecondary'}>
                {sectionTitle}
            </Typography>
            {children}
        </div>
    );
};

CardSectionHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default withStyles(styles)(CardSectionHeader);
