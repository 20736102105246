const styles = (theme) => ({
    attentionBox: {
        width: '100%',
        minHeight: '100px',
        backgroundColor: `${theme.palette.primary.main}1A`,
        padding: theme.spacing(2),
    },
});

export default styles;
