import React from 'react';
import { ButtonBase, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';

const AnimatedHamburgerMenu = (props) => {
    const { classes, isOpen, ...other } = props;

    /**
     * Renders a bar of the three bar hamburger menu along with its animation class
     */
    const renderBar = (animatedClass) => {
        return <div className={clsx(classes.menuBarBase, isOpen && animatedClass)} />;
    };

    return (
        <ButtonBase
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            {...other}
        >
            <div className={classes.root}>
                {renderBar(classes.animatedTopBar)}
                {renderBar(classes.animatedMidBar)}
                {renderBar(classes.animatedBottomBar)}
            </div>
        </ButtonBase>
    );
};

AnimatedHamburgerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AnimatedHamburgerMenu);
