import isURL from 'validator/lib/isURL';

export const centsToDollars = (price) => (price / 100).toFixed(2);

export const formatArrayAsList = (list, attribute) => {
    if (list.length === 0) return '';

    let resultString = list[0][attribute];
    for (let index = 1; index < list.length; index++)
        resultString += `, ${list[index][attribute]}`;
    return resultString;
};

export const formatDate = (numString) =>
    new Date(parseInt(numString))
        .toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
        .replace(',', '');

export const splitSocialMediaLink = (link) => {
    const emptyValue = !Boolean(link);
    if (!emptyValue && isURL) {
        // parse out the username at the end of the url
        const pathIndex = link.lastIndexOf('/');

        // parse out the domian prefix e.g., https://www.youtube.com/c/
        const normalizedPrefix = link.slice(0, pathIndex + 1);

        // get username
        const normalizedUsername = link.slice(pathIndex + 1);

        return [normalizedPrefix, normalizedUsername];
    }

    return [];
};

/**
 * Helper function for testing social media input values:
 * Will check that domain name is a valid socialmedia platform.
 *
 * returns true if valid, false otherwise
 */
export const isValidSocialDomainPrefix = (value, socialMediaPlatform) => {
    const emptyValue = !Boolean(value);

    if (!emptyValue && isURL(value)) {
        // Narrow url paramaters to whitelisted domains and secure protocols
        return isURL(value, {
            require_host: true,
            host_whitelist: [
                `www.${socialMediaPlatform}.com`,
                `${socialMediaPlatform}.com`,
            ],
        });
    }

    return true;
};

/**
 * Checks that the input youtube url is a valid user value
 * Here, we only support recent youtube user links:
 * youtube.com/channel/{channelId}
 * youtube.com/c/{channelId}
 * youtube.com/user/{username}
 *
 * the legacy youtube.com/{username} is not supported
 */
export const whiteListedYoutubePaths = (value) => {
    if (Boolean(value) && isURL(value)) {
        const [normalizedPrefix] = splitSocialMediaLink(value);

        if (normalizedPrefix.includes('youtube.com/channel/')) {
            return true;
        }

        if (normalizedPrefix.includes('youtube.com/c/')) {
            return true;
        }

        if (normalizedPrefix.includes('youtube.com/user/')) {
            return true;
        }
        return false;
    }

    // if the value was empty, we say this is a valid link because the value can be empty
    return true;
};

/**
 * Checks the input value for characters that "look" like a url but is actually a
 * malformed url. for example (https:/www.youtube.com) is almost a valid url but is missing
 * a '/'
 */
export const isNotMalformedInput = (value) => {
    // Check that input value is not empty and not a url
    if (Boolean(value) && !isURL(value)) {
        // Check for anything that looks like a malformed url
        const pattern = /\//gi;
        return !value.match(pattern);
    }

    // otherwise it's good
    return true;
};

/**
 * Because the user is only entering a username into a social media field, we supply
 * a default url prefix for them.
 */
export const getDefaultSocialMediaPrefixes = (type) => {
    switch (type) {
        case 'facebook':
            return 'facebook.com/';
        case 'instagram':
            return 'instagram.com/';
        case 'twitter':
            return 'twitter.com/';
        case 'soundcloud':
            return 'soundcloud.com/';
        case 'youtube':
            return 'youtube.com/channel/';
        default:
            break;
    }
};

/**
 * Normalize link removes protocols and the www. prefixes and appends a '//'
 * to allow hrefs to open non-www links in a new window rather than appending them
 * to the local domain (e.g, submissions.elevatormag.com/user/test/facebook.com/test)
 */
export const normalizeLink = (linkRaw, addPrefix = true) => {
    return (addPrefix ? '//' : '') + linkRaw.replace('https://', '').replace('www.', '');
};

/**
 * Interpolates the proper renaming of a submission type based of the database value
 */
export const interpolateSubmissionType = (value) => {
    switch (value) {
        case 'Paid':
            return 'Sponsored Post';
        case 'Featured':
            return 'Featured Sponsored Post';
        case 'Standard':
            return 'Standard Submission';
        case 'Preferred':
            return value;
        default:
            return '';
    }
};
