const styles = (theme) => ({
    disablePaperMargin: {
        margin: 0,
        padding: 0,
    },
    accountMenu: {
        minWidth: '330px',
        border: 'none',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        height: '80px',
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    accountInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
    },
    avatarContainer: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(1.8),
    },
    accountCardAvatar: {
        width: '45px',
        height: '45px',
        backgroundColor: theme.palette.primary.dark,
    },
    userName: {
        fontSize: '1rem',
    },
    listItemIcon: {
        paddingRight: theme.spacing(2),
        marginRight: 0,
        minWidth: '14px',
        fontSize: 14,
    },
    defaultColor: {
        color: theme.palette.text.secondary,
    },
    listItemText: {
        fontWeight: 500,
        color: theme.palette.text.secondary,
        minHeight: '45px',
        fontWeight: 500,
        fontSize: '0.85rem',
    },
    highEmphasis: {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    listSep: {
        margin: theme.spacing(1, 0),
    },
});

export default styles;
