const styles = (theme) => ({
    cookieDisclaimer: {
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        zIndex: 10,
        minHeight: '80px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    cookieDisclaimerContent: {
        padding: theme.spacing(1, 0),
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    cookieDisclaimerText: {
        color: 'white',
        paddingRight: theme.spacing(2),
        fontSize: '0.9rem',
    },
});

export default styles;
