const styles = (theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',

        // mobile
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2, 0, 0, 0),
        },
        maxWidth: `calc(${theme.static_component_sizes.content_max_width} + 100px)`,
        padding: theme.spacing(2),
    },
    footerPadding: {
        paddingBottom: '15vh',
    },
    disableTopPadding: {
        paddingTop: 0,
    },
});

export default styles;
