const styles = (theme) => ({
    root: {
        width: '100%',
    },
    disablePaperMargins: {
        padding: 0,
    },

    cardHeaderContainer: {
        padding: theme.spacing(2),
    },
    cardBodyContainer: {
        padding: theme.spacing(2),
    },
    attachedImage: {
        width: '120px',
        height: '120px',
        objectFit: 'cover',
    },
    imagePlaceholder: {
        width: '120px',
        height: '120px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
    actionButton: {
        minWidth: '200px',
    },
    infoIcon: {
        color: theme.palette.submissionStatuses.flagged,
    },
    openExternalIcon: {
        fontSize: theme.typography.pxToRem(12),
    },
    streamingLink: {
        color: theme.palette.primary.main,
    },
});

export default styles;
