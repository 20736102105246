const styles = (theme) => ({
    legacyLink: {
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '700',
        fontSize: theme.typography.pxToRem(12),
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
            backgroundColor: 'transparent',
        },
        '&:link': {
            textDecoration: 'none',
        },
    },
    lowContrastText: {
        color: theme.palette.legacyColors.darkInputColors.input,
    },
    largeFont: {
        fontSize: theme.typography.pxToRem(16),
    },
    greyText: {
        color: `${theme.palette.branding.elevator_grey}4D`,
    },
    redText: {
        color: `${theme.palette.branding.elevator_red}`,
    },
});

export default styles;
