import { withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const DarkButton = (props) => {
    const { classes, children, ...rest } = props;

    return (
        <Button
            variant="outlined"
            fullWidth
            classes={{
                root: classes.darkButton,
                disabled: classes.disabledState,
            }}
            {...rest}
        >
            {children}
        </Button>
    );
};

DarkButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DarkButton);
