import gql from 'graphql-tag';

export const USERNAME_EXISTS = gql`
    query userExists($username: String) {
        usernameUsed: userExists(preferredUsername: $username)
    }
`;

export const EMAIL_EXISTS = gql`
    query userExists($email: String) {
        emailUsed: userExists(email: $email)
    }
`;

export const USERNAME_OR_EMAIL_EXISTS = gql`
    query userExists($email: String, $username: String) {
        emailUsed: userExists(email: $email)
        usernameUsed: userExists(preferredUsername: $username)
    }
`;

export const MY_USER_AND_PERMISSIONS = gql`
    query myUser {
        myUser {
            userId
            email
            emailVerified
            username
            preferredUsername
            displayName
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            banner {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            createdAt
            updatedAt
            permissionGroup {
                groupName
            }
        }
    }
`;

export const SEARCH_USERS = gql`
    query searchUsers($input: String!) {
        searchUsers(input: $input) {
            preferredUsername
            email
            socialMedia {
                type
                link
            }
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;

export const USER_PROFILE = gql`
    query profile($preferredUsername: String!) {
        profile(preferredUsername: $preferredUsername) {
            userId
            preferredUsername
            displayName
            preferred
            role
            socialMedia {
                type
                link
            }
            avatar {
                sourceUrl
                sourceSet
            }
            banner {
                sourceUrl
                sourceSet
            }
            bio
            permissionGroup {
                groupName
            }
        }
    }
`;
