import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const PageTitleDescription = (props) => {
    const { classes, pageTitle, description } = props;

    return (
        <div className={classes.root}>
            <Typography className={classes.pageTitle} variant={'h1'} gutterBottom>
                {pageTitle}
            </Typography>

            <Typography component={'span'} variant="body1" gutterBottom>
                {description}
            </Typography>

            {props.children}
        </div>
    );
};

PageTitleDescription.propTypes = {
    classes: PropTypes.object.isRequired,
    pageTitle: PropTypes.string.isRequired,
    description: PropTypes.node,
};

export default withStyles(styles)(PageTitleDescription);
