import React from 'react';
import Grid from '@material-ui/core/Grid';

/**
 * Grid Wrapping Component. This wrapping component
 * creates an explicit wrapper around the Grid container component
 * to improve readability of code between a Grid item and Grid Container
 * @param {*} props
 */
const GridContainer = (props) => {
    return <Grid container {...props} />;
};

export default GridContainer;
