const styles = (theme) => ({
    listItem: {
        height: '85px',
        padding: 0,
    },
    listItemSep: {
        position: 'relative',
    },
    listContainer: {
        padding: theme.spacing(0, 2, 0, 2),
        width: '100%',
        backgroundColor: theme.palette.background.default,
        // Mobile styles
        [theme.breakpoints.down(1000)]: {
            backgroundColor: theme.palette.background.paper,
            width: '100%',
        },
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
    },
});

export default styles;
