const DESKTOP_STEPPER_HEIGHT = '60px';
const MOBILE_STEPPER_HEIGHT = '50px';

const styles = (theme) => ({
    //layout
    desktopStepper: {
        display: 'block',
        // Hidden on smaller screens
        [theme.breakpoints.down(1000)]: {
            display: 'none',
        },
    },
    mobileStepper: {
        display: 'block',
        // Hidden on larger screens
        [theme.breakpoints.up(1000)]: {
            display: 'none',
        },
    },
    mobileStepperContainer: {
        // position: 'fixed',
        width: '100%',
        zIndex: 1,
    },
    mobileStepController: {
        backgroundColor: theme.palette.branding.elevator_grey_light,
        height: MOBILE_STEPPER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: 0,
        padding: 0,
    },
    headerText: {
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(16),
        paddingLeft: theme.spacing(3),
    },
    backButton: {
        height: '100%',
        width: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    disableBorderRadius: {
        borderRadius: 0,
    },
    arrowIcon: {
        color: theme.palette.common.white,
    },

    // Desktop Stepper
    desktopStepperContainer: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: 1,
    },
    DesktopStepper: {
        width: '100%',
        maxWidth: '850px',
        backgroundColor: 'transparent',
    },
    desktopStepperLabelInactive: {
        fontSize: theme.typography.pxToRem(14),
    },
    desktopStepperLabelActive: {
        fontWeight: 'bold',
    },

    // stepper content:
    stepperContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: DESKTOP_STEPPER_HEIGHT,
        [theme.breakpoints.down(1000)]: {
            paddingTop: 0, //MOBILE_STEPPER_HEIGHT
        },
    },
    stepperCard: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingBottom: '20vh',
        maxWidth: '1100px',
        [theme.breakpoints.down(1000)]: {
            backgroundColor: theme.palette.background.default,
            paddingBottom: '15vh',
        },
    },
});

export default styles;
