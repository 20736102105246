const styles = (theme) => ({
    chip: {
        padding: 0,
        margin: 0,
        fontSize: theme.typography.pxToRem(6),
        height: '14px',
    },
    pending: {
        color: theme.palette.common.black + '80',
        backgroundColor: theme.palette.submissionStatuses.pending,
    },
    flagged: {
        color: theme.palette.common.black + '80',
        backgroundColor: theme.palette.submissionStatuses.flagged,
    },
    published: {
        color: theme.palette.common.black + '80',
        backgroundColor: theme.palette.submissionStatuses.published,
    },
    rejected: {
        color: theme.palette.common.black + '80',
        backgroundColor: theme.palette.submissionStatuses.rejected,
    },
    approved: {
        color: theme.palette.common.black + '80',
        backgroundColor: theme.palette.submissionStatuses.approved,
    },

    // Popover styles
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
});

export default styles;
