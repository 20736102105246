import React from 'react';
import { withStyles } from '@material-ui/core';
import { Instagram, Soundcloud, Twitter, Youtube, Facebook } from 'mdi-material-ui';
import SOCIAL_MEDIA_TYPES from '../../config/SocialMediaTypes';
import PropTypes from 'prop-types';
import styles from './styles';
import { normalizeLink } from '../../lib/util';

const SocialMediaLink = (props) => {
    const { classes, type, link } = props;

    const renderLink = () => {
        switch (type.toLowerCase()) {
            case SOCIAL_MEDIA_TYPES.INSTAGRAM:
                return (
                    <a href={normalizeLink(link)} target="_blank">
                        <Instagram className={classes.icon} />
                    </a>
                );
            case SOCIAL_MEDIA_TYPES.YOUTUBE:
                return (
                    <a href={normalizeLink(link)} target="_blank">
                        <Youtube className={classes.icon} />
                    </a>
                );
            case SOCIAL_MEDIA_TYPES.SOUNDCLOUD:
                return (
                    <a href={normalizeLink(link)} target="_blank">
                        <Soundcloud className={classes.icon} />
                    </a>
                );
            case SOCIAL_MEDIA_TYPES.TWITTER:
                return (
                    <a href={normalizeLink(link)} target="_blank">
                        <Twitter className={classes.icon} />
                    </a>
                );
            case SOCIAL_MEDIA_TYPES.FACEBOOK:
                return (
                    <a href={normalizeLink(link)} target="_blank">
                        <Facebook className={classes.icon} />
                    </a>
                );
            default:
                console.error(`Unrecognized Social Media Type: ${type}`);
                return null;
        }
    };

    return renderLink();
};

SocialMediaLink.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.oneOf(Object.values(SOCIAL_MEDIA_TYPES)).isRequired,
    link: PropTypes.string.isRequired,
};

export default withStyles(styles)(SocialMediaLink);
