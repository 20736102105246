import React from 'react';
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Image, OpenInNew } from 'mdi-material-ui';
import Router from 'next/router';
import { DOMAIN_BASE, NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import SUBMISSION_STATUSES from '../../config/SubmissionStatuses';
import { GridContainer, GridItem } from '../Grid';
import Link from '../Link';
import ShareButtons from '../ShareButtons';
import SubmissionStatusChip from '../SubmissionStatusChip';
import PropTypes from 'prop-types';
import styles from './styles';
import { centsToDollars, formatDate, interpolateSubmissionType } from '../../lib/util';

const SubmissionCard = (props) => {
    const { classes, submission } = props;

    const {
        contentTitle,
        contentUrl,
        status,
        createdAt,
        contentDescription,
        submissionType,
        contentImage,
        order,
        orderNumber,
        flagged,
        post,
    } = submission;

    const { amountPaid } = order;

    const statusLabel = flagged ? 'FLAGGED' : status;

    let postLink;

    if (statusLabel === SUBMISSION_STATUSES.COMPLETED && post) {
        postLink = `${DOMAIN_BASE}/${post.slug}`;
    }

    const onViewPostClick = () => {
        window.open(postLink, '_blank');
    };

    const onSeeMoreClick = () => {
        Router.push(NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS.route + '/' + orderNumber);
    };

    const renderCardHeaderItem = (fieldName, value, direction = 'column') => {
        return (
            <Box display={'flex'} flexDirection={direction} minWidth={'80px'}>
                <Typography variant={'subtitle2'}>{fieldName}</Typography>

                <Typography variant={'body2'}>
                    {direction === 'row' && ': '} {value}
                </Typography>
            </Box>
        );
    };

    const renderCardHeader = () => {
        return (
            <GridContainer direction={'row'} alignItems={'center'} spacing={2}>
                <GridItem>{renderCardHeaderItem('Date', formatDate(createdAt))}</GridItem>
                <GridItem>
                    {renderCardHeaderItem('Order Num', `#${orderNumber}`)}
                </GridItem>
                <GridItem>
                    {renderCardHeaderItem('Total', `$${centsToDollars(amountPaid)}`)}
                </GridItem>
                <GridItem>
                    {renderCardHeaderItem(
                        'Type',
                        interpolateSubmissionType(submissionType),
                    )}
                </GridItem>

                <GridItem xs>
                    <Box
                        width={'100%'}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                    >
                        <SubmissionStatusChip
                            variant={SUBMISSION_STATUSES[statusLabel.toUpperCase()]}
                        />
                    </Box>
                </GridItem>
            </GridContainer>
        );
    };

    const renderShareButtons = () => {
        return (
            <ShareButtons
                shareLink={postLink}
                size={'small'}
                justifyContent={'flex-end'}
                postTitle={contentTitle}
            />
        );
    };

    const renderSubmissionData = () => {
        return (
            <GridContainer direction={'row'} spacing={2} alignContent={'flex-start'}>
                <GridItem xs>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                    >
                        <Box display={'flex'} flexDirection={'row'}>
                            {contentImage && (
                                <img
                                    src={contentImage && contentImage.sourceUrl}
                                    srcSet={contentImage && contentImage.sourceSet}
                                    className={classes.attachedImage}
                                />
                            )}
                            {!contentImage && (
                                <Box className={classes.imagePlaceholder}>
                                    <Image fontSize={'large'} color={'secondary'} />
                                </Box>
                            )}
                            <Box paddingLeft={2} maxWidth={'80%'}>
                                <Typography
                                    href={
                                        NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS.route +
                                        '/' +
                                        orderNumber
                                    }
                                    component={Link}
                                    variant={'subtitle1'}
                                >
                                    {contentTitle}
                                </Typography>
                                <Typography variant={'body2'} gutterBottom>
                                    {contentDescription}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    component={'a'}
                                    href={contentUrl}
                                    target={'_blank'}
                                    className={classes.streamingLink}
                                >
                                    Streaming Link
                                    <OpenInNew className={classes.openExternalIcon} />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </GridItem>

                <GridItem>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            size={'small'}
                            className={classes.actionButton}
                            onClick={onSeeMoreClick}
                        >
                            See More
                        </Button>
                        {statusLabel === SUBMISSION_STATUSES.COMPLETED && (
                            <>
                                <Box paddingTop={2} />
                                <Button
                                    variant={'contained'}
                                    size={'small'}
                                    color={'primary'}
                                    className={classes.actionButton}
                                    onClick={onViewPostClick}
                                >
                                    View Post
                                </Button>
                                <Box paddingTop={2} />
                                {renderShareButtons()}
                            </>
                        )}
                    </Box>
                </GridItem>
            </GridContainer>
        );
    };

    const renderCardBody = () => {
        return (
            <GridContainer direction={'column'} spacing={0}>
                <GridItem>{renderSubmissionData()}</GridItem>
            </GridContainer>
        );
    };

    return (
        <Paper className={classes.disablePaperMargins}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                className={classes.cardHeaderContainer}
            >
                {renderCardHeader()}
            </Box>

            <Divider variant={'fullWidth'} />

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={classes.cardBodyContainer}
            >
                {renderCardBody()}
            </Box>
        </Paper>
    );
};

SubmissionCard.propTypes = {
    classes: PropTypes.object.isRequired,
    submission: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubmissionCard);
