import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const LoadingScreen = () => {
    return (
        <Box
            height={'100vh'}
            width={'100vw'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingScreen;
