import React from 'react';
import {
    AppBar,
    ClickAwayListener,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    withStyles,
    Avatar,
    ListItemAvatar,
    Badge,
    ListItemIcon,
} from '@material-ui/core';
import { ExitToAppOutlined as SignOutIcon } from '@material-ui/icons';
import {
    InboxArrowUp,
    Settings,
    FileDocumentBoxPlusOutline,
    MonitorDashboard,
} from 'mdi-material-ui';
import { DOMAIN_BASE, NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import AnimatedHamburgerMenu from '../AnimatedHamburgerMenu';
import SearchInput from './SearchInput';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles';
import UserBadge from '../UserBadge';

const MobileHeader = (props) => {
    const { classes, handleSignoutClick, currentUser } = props;

    // Tracks whether the menu should be opened or closed
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    // Tracks the current search input field
    const [searchInput, setSearchInput] = React.useState('');

    /**
     * Handler for toggling the menu dropdown
     */
    const toggleMenu = (imperativeValue = null) => {
        if (imperativeValue && typeof imperativeValue === 'boolean') {
            setMenuIsOpen(imperativeValue);
        }

        setMenuIsOpen((prevState) => !prevState);
    };

    /**
     * Handle interactions when a user clicks outside the menu and the the menu
     * is open
     */
    const handleClickAwayFromMenu = () => {
        menuIsOpen && toggleMenu(false);
    };

    /**
     * Handler for the current search input value
     */
    const handleSearchInput = (event) => {
        setSearchInput(event.target.value);
    };

    /**
     * Handler for submiting the search input from/
     * NOTE: this code is handle the legacy wp site's current search params.
     */
    const onSubmitSearchInput = (event) => {
        event.preventDefault();

        // sanitize spaces
        const sanitizedInput = searchInput.replace(/\s/g, '+');

        // Setup query parameters
        const searchURL = `${DOMAIN_BASE}/search?s=${sanitizedInput}`;

        // Let legacy WP site handler search functionality
        window.open(searchURL, '_self');
    };

    /**
     * Interpolates what kind of user badge to display to the user. On Mobile,
     * certain badges take precedence rather than displaying all applicable badges:
     * 1. Elevator Staff
     * 2. Preferred User
     * 3. Verified User
     */
    const interpolateBadgeType = () => {
        if (currentUser.profile.permissionGroup.groupName !== 'Standard') {
            return <UserBadge variant={'staff'} />;
        } else if (currentUser.profile.preferred) {
            return <UserBadge variant={'preferred'} />;
        } else if (currentUser.profile.isVerified) {
            return <UserBadge variant={'verified'} />;
        } else {
            return null;
        }
    };

    const renderNavListItem = ({ displayText, route }, index, collection) => {
        const isLastListItem = index === collection.length - 1;
        const isFirstListITem = index === 0;

        return (
            <div key={index}>
                {isFirstListITem && (
                    <Divider component="li" className={classes.listDivider} />
                )}
                <ListItem component={'a'} href={route} className={classes.listItem}>
                    <ListItemText primary={displayText} />
                </ListItem>
                {!isLastListItem && (
                    <Divider component="li" className={classes.listDivider} />
                )}
            </div>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAwayFromMenu}>
            <div className={classes.root}>
                <AppBar
                    position={'static'}
                    elevation={0}
                    className={classes.mobileAppBar}
                >
                    <Toolbar className={classes.toolbar}>
                        <AnimatedHamburgerMenu onClick={toggleMenu} isOpen={menuIsOpen} />

                        <div className={classes.headerLogoContainer}>
                            <a href={DOMAIN_BASE}>
                                <img
                                    width={'90px'}
                                    src={'/static/img/elevator-header-logo.png'}
                                />
                            </a>
                        </div>

                        <AnimatedHamburgerMenu onClick={toggleMenu} isOpen={menuIsOpen} />
                    </Toolbar>
                </AppBar>
                <div className={classes.collapseMenuContainer}>
                    <Collapse in={menuIsOpen}>
                        <div className={classes.listMenuContainer}>
                            <SearchInput
                                searchInput={searchInput}
                                handleSearchInput={handleSearchInput}
                                onSubmit={onSubmitSearchInput}
                            />
                            <List
                                component="nav"
                                aria-label="navigation options"
                                className={classes.list}
                            >
                                {Object.values(NAVIGATION_ROUTES.LEGACY.HEADER).map(
                                    (routeConfig, index, collection) => {
                                        return renderNavListItem(
                                            routeConfig,
                                            index,
                                            collection,
                                        );
                                    },
                                )}
                            </List>
                            <List
                                component="nav"
                                aria-label="navigation options"
                                className={classes.userOptionsList}
                            >
                                {currentUser && (
                                    <React.Fragment>
                                        <ListItem
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                            )}
                                            component={'a'}
                                            href={NAVIGATION_ROUTES.PROFILE.route}
                                        >
                                            <ListItemAvatar>
                                                <Badge
                                                    badgeContent={interpolateBadgeType()}
                                                >
                                                    <Avatar
                                                        className={classes.userAvatar}
                                                        src={
                                                            currentUser.profile.avatar &&
                                                            currentUser.profile.avatar
                                                                .sourceUrl
                                                        }
                                                    >
                                                        {currentUser.profile.firstName[0].toUpperCase()}
                                                    </Avatar>
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText primary={'My Profile'} />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={
                                                NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS
                                                    .route
                                            }
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <InboxArrowUp />
                                            </ListItemIcon>
                                            <ListItemText primary={'My Submissions'} />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={NAVIGATION_ROUTES.ROOT.route}
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <FileDocumentBoxPlusOutline />
                                            </ListItemIcon>
                                            <ListItemText primary={'New Submission'} />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={
                                                NAVIGATION_ROUTES.ACCOUNT.ACCOUNT_SETTINGS
                                                    .route
                                            }
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <Settings />
                                            </ListItemIcon>
                                            <ListItemText primary={'Account Settings'} />
                                        </ListItem>

                                        {currentUser.profile.permissionGroup.groupName !==
                                            'Standard' && (
                                            <ListItem
                                                component={'a'}
                                                href={
                                                    NAVIGATION_ROUTES.ADMIN_DASHBOARD.uri
                                                }
                                                className={clsx(
                                                    classes.listItemHighEmphasis,
                                                    classes.mobileList,
                                                )}
                                            >
                                                <ListItemIcon
                                                    className={classes.listIcon}
                                                >
                                                    <MonitorDashboard />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'Go To Admin Dashboard'}
                                                />
                                            </ListItem>
                                        )}

                                        <ListItem
                                            onClick={handleSignoutClick}
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                            )}
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <SignOutIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Sign out'} />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                                {!currentUser && (
                                    <>
                                        <Divider
                                            component="li"
                                            className={classes.listDivider}
                                        />
                                        <ListItem
                                            component={'a'}
                                            href={NAVIGATION_ROUTES.SIGN_IN.route}
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                            )}
                                        >
                                            <ListItemText primary={'Sign In'} />
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </div>
                    </Collapse>
                </div>
            </div>
        </ClickAwayListener>
    );
};

MobileHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSignoutClick: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
};

export default withStyles(styles)(MobileHeader);
