const styles = (theme) => ({
    textFieldContainer: {
        width: '100%',
    },
    imageUploadsDisclaimer: {
        paddingTop: theme.spacing(2),
    },
    errorText: {
        color: 'red',
    },
    imageContainer: {
        paddingTop: theme.spacing(1),
    },
    formRoot: {
        width: '100%',
    },
    buttonSpacer: {
        width: theme.spacing(2),
    },
    fileName: {
        paddingRight: theme.spacing(1),
    },
    iconButton: {
        color: 'white',
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 2,
    },
    removeButton: {
        color: 'white',
        position: 'absolute',
        bottom: theme.spacing(6),
        right: theme.spacing(1),
        zIndex: 2,
    },
    bannerContainer: {
        height: '350px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
        position: 'relative',
    },
    gradientFade: {
        height: '100%',
        width: '100%',
        zIndex: 1,
        background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 05%, rgba(0,0,0,0) 100%)',
    },
    banner: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    avatar: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        backgroundColor: theme.palette.branding.elevator_grey_light,
        fontSize: theme.typography.pxToRem(40),
    },
    avatarContainer: {
        position: 'relative',
        height: '150px',
        width: '150px',
        borderRadius: '4%',
        overflow: 'hidden',
    },
});

export default styles;
