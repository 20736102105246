import React from 'react';
import { Box, withStyles, ButtonBase, Input } from '@material-ui/core';
import clsx from 'clsx';
import { ContentCopy } from 'mdi-material-ui';
import { withSnackbar } from 'notistack';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';
import PropTypes from 'prop-types';
import styles from './styles';

const ShareButtons = (props) => {
    const {
        classes,
        shareLink,
        postTitle,
        size,
        justifyContent,
        enqueueSnackbar,
    } = props;

    // Create ref to hidden textfield so we can transmit share link to user's clipboard.
    const hiddenTextAreaRef = React.useRef(null);

    const contentSize = size === 'small' ? 32 : 50;

    /**
     * Adpated from:
     * https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
     * @Nate
     */
    const onCopyToClipboard = (e) => {
        hiddenTextAreaRef.current.select();
        document.execCommand('copy');

        e.target.focus();
        onShareFinished('Link copied to clipboard');
    };

    const onShareFinished = (msg) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'info',
            preventDuplicate: true,
        });
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={justifyContent}
            alignItems={'center'}
        >
            <FacebookShareButton url={shareLink} className={classes.shareButton}>
                <FacebookIcon size={contentSize} />
            </FacebookShareButton>
            <Box paddingLeft={2}>
                <TwitterShareButton url={shareLink} className={classes.shareButton}>
                    <TwitterIcon size={contentSize} />
                </TwitterShareButton>
            </Box>
            <Box paddingLeft={2}>
                <EmailShareButton
                    subject={`Checkout this post on ELEVATOR: "${postTitle}"`}
                    url={shareLink}
                    className={classes.shareButton}
                >
                    <EmailIcon size={contentSize} />
                </EmailShareButton>
            </Box>
            <Box paddingLeft={2}>
                <ButtonBase onClick={onCopyToClipboard}>
                    <Box
                        className={clsx(
                            classes.contentCopyButton,
                            size === 'small' ? classes.smallButton : classes.largeButton,
                        )}
                    >
                        <ContentCopy />
                    </Box>
                </ButtonBase>
                <Box id={'link-share-target'} position={'absolute'} margin={-9999}>
                    <Input readOnly inputRef={hiddenTextAreaRef} value={shareLink} />
                </Box>
            </Box>
        </Box>
    );
};

ShareButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    shareLink: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['small', 'large']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end']),
    postTitle: PropTypes.string,
};
ShareButtons.defaultProps = {
    size: 'small',
    justifyContent: 'flex-start',
};
const StyledShareButtons = withStyles(styles)(ShareButtons);
export default withSnackbar(StyledShareButtons);
