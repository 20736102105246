const styles = (theme) => ({
    textContent: {
        color: theme.palette.common.black,
        fontSize: theme.typography.pxToRem(18),
        textTransform: 'uppercase',
    },
    divider: {
        color: theme.palette.common.black,
        border: `1px solid ${theme.palette.common.black}`,
    },
});

export default styles;
