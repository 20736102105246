const SUBMISSION_STATUSES = {
    REVIEW: 'Review',
    APPROVED: 'Approved',
    APPROVAL: 'Approval',
    FLAGGED: 'Flagged',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected',
};

Object.freeze(SUBMISSION_STATUSES);

export default SUBMISSION_STATUSES;
