const styles = (theme) => ({
    darkButton: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: '600',
        backgroundColor: 'transparent',
        border: `2px solid ${theme.palette.legacyColors.darkInputColors.input}`,
        color: theme.palette.legacyColors.darkInputColors.input,
        height: '50px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'red',
            color: theme.palette.common.black,
            border: `2px solid red`,
        },
        '&:focus': {
            border: `2px solid red`,
            backgroundColor: 'red',
            color: theme.palette.common.black,
        },
    },
    disabledState: {
        backgroundColor: theme.palette.branding.elevator_grey_light,
    },
});

export default styles;
