const styles = (theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
    },
    pageTitle: {
        fontSize: theme.typography.pxToRem(26),
    },
});

export default styles;
