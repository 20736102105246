const styles = (theme) => ({
    inputLabelRoot: {
        color: theme.palette.legacyColors.darkInputColors.inputLabel,
        '&:focused': {
            color: theme.palette.common.white,
        },
    },
    inputLabelFocused: {
        color: 'white',
    },

    inputRoot: {
        backgroundColor: theme.palette.branding.elevator_grey_mid,
        maxHeight: '46px',
        width: '100%',
        color: theme.palette.legacyColors.darkInputColors.input,
        padding: theme.spacing(2, 0),
        border: '2px solid transparent',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '500',
    },
    inputRootFocused: {
        border: `2px solid ${theme.palette.legacyColors.darkInputColors.input}`,
        borderRadius: '3px',
    },
    inputRootDisabled: {
        backgroundColor: 'transparent',
        color: theme.palette.legacyColors.darkInputColors.input,
        border: `1px dashed ${theme.palette.legacyColors.darkInputColors.inputDisabledBorder}`,
        borderRadius: '3px',
    },
    inputError: {
        border: `2px solid ${theme.palette.legacyColors.darkInputColors.inputError}`,
        borderRadius: '3px',
    },
    input: {
        border: 'none',
    },
});

export default styles;
