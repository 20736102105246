import React from 'react';
import { Typography, useMediaQuery, withStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { LegacyNavLink } from '..';
import { NAVIGATION_ROUTES, SOCIAL_MEDIA } from '../../config/NavigationRoutes';
import DarkButton from '../DarkButton';
import { GridContainer, GridItem } from '../Grid';
import NewsLetterTextField from './NewsLetterTextField';
import styles from './styles';

const Footer = (props) => {
    const { classes } = props;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const subscribeCTA = () => {
        return (
            <form
                action="//elevatormag.us4.list-manage.com/subscribe/post"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
            >
                <input type="hidden" name="u" value="fdb294b302e48366941d0cde7" />
                <input type="hidden" name="id" value="f0b257f0f1" />
                <GridContainer direction={'row'} spacing={1}>
                    <GridItem sm={12}>
                        <Typography variant={'h5'} className={classes.newsLetterCTAText}>
                            Subscribe to elevator
                        </Typography>
                    </GridItem>
                    <GridItem xs={6} className={classes.textFieldContainer}>
                        <NewsLetterTextField
                            placeholder={'First Name'}
                            type={'text'}
                            name="MMERGE1"
                            id="mce-MMERGE1"
                        />
                    </GridItem>
                    <GridItem xs={6} className={classes.textFieldContainer}>
                        <NewsLetterTextField
                            placeholder={'Last Name'}
                            type={'text'}
                            name="MMERGE2"
                            id="mce-MMERGE2"
                        />
                    </GridItem>
                    <GridItem xs={12} md={12} className={classes.textFieldContainer}>
                        <NewsLetterTextField
                            type={'email'}
                            placeholder={'Email'}
                            name="EMAIL"
                            id="mce-EMAIL"
                        />
                    </GridItem>
                    {/** real people should not fill this in and expect good things - do not remove this or risk form bot signups **/}
                    <div
                        style={{ position: 'absolute', left: '-5000px' }}
                        aria-hidden="true"
                    >
                        <input
                            type="text"
                            name="b_fdb294b302e48366941d0cde7_f0b257f0f1"
                            tabIndex="-1"
                            value=""
                            readOnly
                        />
                    </div>
                    <GridItem xs={12} md={12}>
                        <DarkButton
                            type={'submit'}
                            fullWidth
                            name="subscribe"
                            id="mc-embedded-subscribe"
                        >
                            Subscribe
                        </DarkButton>
                    </GridItem>
                </GridContainer>
            </form>
        );
    };

    const renderSocialMedia = () => {
        return (
            <GridContainer
                direction={'row'}
                alignItems={'center'}
                justify={mobile ? 'center' : 'flex-end'}
                spacing={4}
            >
                {Object.values(SOCIAL_MEDIA).map((routeConfig, index) => (
                    <GridItem key={index}>
                        <a href={routeConfig.route}>
                            <routeConfig.icon className={classes.socialMediaIcon} />
                        </a>
                    </GridItem>
                ))}
            </GridContainer>
        );
    };

    const renderCopyRightNotice = () => {
        return (
            <div className={classes.copyrightNoticeContainer}>
                <img src={'/static/img/footer_logo.svg'} className={classes.footerLogo} />
                <Typography variant={'subtitle2'} className={classes.copyrightNotice}>
                    ELEVATOR © 2019 · All Rights Reserved
                </Typography>
            </div>
        );
    };

    const renderLinks = () => {
        return (
            <GridContainer
                direction={'row'}
                alignItems={'flex-end'}
                justify={mobile ? 'center' : 'flex-end'}
                spacing={2}
            >
                {Object.values(NAVIGATION_ROUTES.LEGACY.FOOTER).map(
                    (routeConfig, index) => (
                        <GridItem
                            key={index}
                            xs={mobile ? 12 : false}
                            className={classes.footerLinkContainer}
                        >
                            <LegacyNavLink
                                fontSizeType={mobile ? 'large' : 'regular'}
                                lowContrastText
                                href={routeConfig.route}
                            >
                                {routeConfig.displayText}
                            </LegacyNavLink>
                        </GridItem>
                    ),
                )}
            </GridContainer>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.footerContentContainer}>
                <GridContainer
                    direction={'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                >
                    <GridItem xs={12} md={6} className={classes.footerContainerLarge}>
                        {subscribeCTA()}
                    </GridItem>
                    <GridItem xs={12} md={6} className={classes.footerContainerLarge}>
                        {renderSocialMedia()}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    direction={mobile ? 'column-reverse' : 'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                >
                    <GridItem xs={12} md={6} className={classes.footerContainerSmall}>
                        {renderCopyRightNotice()}
                    </GridItem>
                    <GridItem xs={12} md={6} className={classes.footerContainerSmall}>
                        {renderLinks()}
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

export default withStyles(styles)(Footer);
